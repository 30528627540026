import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "@/common/constants/api.router.constants";
import { IStaffDailyRoutine, IStaffDailyRoutineParams } from "../typings";
import { AxiosResponse } from "axios";
import { StrictUseAxiosReturn, useAxios } from "@vueuse/integrations/useAxios";
import { AnyFn } from "@vueuse/core";

const StaffDailyRoutineService = {
  getSetting: async (
    params: IStaffDailyRoutineParams,
    onSuccess: AnyFn,
    onError: AnyFn
  ): Promise<StrictUseAxiosReturn<AxiosResponse<IStaffDailyRoutine[]>, AxiosResponse, IStaffDailyRoutine[]>> => {
    const { staff_id } = params;
    const { execute } = useAxios<AxiosResponse<IStaffDailyRoutine[]>>(
      `${ApiRouterConstants.API_STAFF}/${staff_id}/daily_routines`,
      { method: "GET", params },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute();
  },
};

export default StaffDailyRoutineService;
