import { useAxios } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";
import http from "@/common/utils/http-common-utils";
import type { ApiMedicalMenuCategoryIndexReq, ApiMedicalMenuCategoryReq } from "@/clinic/typings";

const ServiceTagService = {
  fetchServiceTag: async (params: any, data: any) => {
    const { execute } = useAxios(ApiRouterConstants.API_SERVICE_TAGS, { method: "GET", params: params }, http, {
      immediate: false,
    });
    return await execute();
  },
  createServiceTag: async (params: any, data: any) => {
    const { execute } = useAxios(
      ApiRouterConstants.API_SERVICE_TAGS,
      { method: "POST", params: params, data: data },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  updateServiceTag: async (id: any, params: any, data: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_SERVICE_TAGS}/${id}`,
      { method: "PUT", params: params, data: data },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  destroyServiceTag: async (id: any, params: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_SERVICE_TAGS}/${id}`,
      { method: "DELETE", params: params },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
};

export default ServiceTagService;
