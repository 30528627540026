import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "@/customer/constants/api.router.constants";
import { IBookingSetting } from "@/clinic/typings";
import { AxiosResponse } from "axios";
import { StrictUseAxiosReturn, useAxios } from "@vueuse/integrations/useAxios";

const BookingSettingService = {
  loadSetting: async (
    clinicId: number
  ): Promise<StrictUseAxiosReturn<AxiosResponse<IBookingSetting>, AxiosResponse, IBookingSetting>> => {
    const { execute } = useAxios<AxiosResponse<IBookingSetting>>(
      `${ApiRouterConstants.API_BOOKING_SETTING}?clinic_id=${clinicId}`,
      { method: "GET" },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
};

export default BookingSettingService;
