import { MaybeRefOrGetter } from "@vueuse/core";
import type { Rule, Rules } from "async-validator";
import { avatarStaffValidate } from "@/clinic/validates/validate.staff.avatar";
import { get } from "@vueuse/core";

const rules: MaybeRefOrGetter<Record<string, Rule | Rules>> = {
  name_kanji: {
    type: "string",
    required: true,
    transform: value => value.trim(),
    message: "*このフィールドは必須です。",
  },
  name_kana: {
    type: "string",
    required: true,
    pattern: /[\u30a0-\u30ff]/,
    transform: value => value.trim(),
    message: "*このフィールドは必須です。",
  },
  email: {
    type: "email",
    required: true,
    transform: value => value.trim(),
    message: "メールアドレスは必須です。",
  },
  inputUploadImage: {
    asyncValidator: async (_, value) => {
      if (value) {
        const result = await avatarStaffValidate(get(value.files));
        return new Promise((resolve, reject) => {
          if (result === true) {
            resolve(value);
          } else {
            reject(result);
          }
        });
      }
    },
  },
};
export default rules;
