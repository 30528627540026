import axios, { AxiosInstance } from "axios";
import RouterConstants from "../constants/app.constants";
import { setupAuth } from "../interceptors/auth.interceptor";

const formDataHttp: AxiosInstance = axios.create({
  baseURL: RouterConstants.API_URL,
  headers: { "Content-Type": "multipart/form-data" },
});

setupAuth(formDataHttp);

export default formDataHttp;
