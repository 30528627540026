import { MaybeRefOrGetter } from "@vueuse/core";
import type { Rule, Rules } from "async-validator";
import {
  START_END_TIME_MESSAGE,
  END_START_TIME_MESSAGE,
  END_START_MID_DAY_TIME_MESSAGE,
  START_END_MID_DAY_TIME_MESSAGE,
} from "@/common/constants/validate-message.constants";

export interface IFormRuleValidationCommon {
  startTimeMorning: number;
  endTimeMorning: number;
  startTimeAfterNoon: number;
  endTimeAfterNoon: number;
}

export const validateTimeRange = (startTime: number, endTime: number, midDay = false): boolean => {
  if (isNaN(startTime) || isNaN(endTime)) {
    return true;
  } else {
    return midDay ? startTime <= endTime : startTime < endTime;
  }
};
export const commonTimeWorkRules = (
  fieldTime: IFormRuleValidationCommon
): MaybeRefOrGetter<Record<string, Rule | Rules>> => {
  const { startTimeMorning, endTimeMorning, startTimeAfterNoon, endTimeAfterNoon } = fieldTime;

  return {
    startTimeMorning: [
      {
        validator: () => validateTimeRange(startTimeMorning, endTimeMorning),
        message: START_END_TIME_MESSAGE,
      },
    ],
    endTimeMorning: [
      {
        validator: () => validateTimeRange(startTimeMorning, endTimeMorning),
        message: END_START_TIME_MESSAGE,
      },
      {
        validator: () => validateTimeRange(endTimeMorning, startTimeAfterNoon, true),
        message: END_START_MID_DAY_TIME_MESSAGE,
      },
    ],
    startTimeAfterNoon: [
      {
        validator: () => validateTimeRange(endTimeMorning, startTimeAfterNoon, true),
        message: START_END_MID_DAY_TIME_MESSAGE,
      },
      {
        validator: () => validateTimeRange(startTimeAfterNoon, endTimeAfterNoon),
        message: START_END_TIME_MESSAGE,
      },
    ],
    endTimeAfterNoon: [
      {
        validator: () => validateTimeRange(startTimeAfterNoon, endTimeAfterNoon),
        message: END_START_TIME_MESSAGE,
      },
    ],
  };
};
export default commonTimeWorkRules;
