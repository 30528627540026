export default class {
  public static countLines(element: any): number {
    const prevLH = element.style.lineHeight;
    const factor = 1000;
    element.style.lineHeight = factor + "px";

    const height = element.getBoundingClientRect().height;
    element.style.lineHeight = prevLH;

    return Math.floor(height / factor);
  }
}
