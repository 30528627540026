import moment from "moment";
import { MILLISECONDS_IN_DAY } from "../typings/constants/booking";
export default class DateUtils {
  public static parseToUTCTime(value: Date | null): string {
    return moment.utc(value?.toUTCString()).format("HH:mm");
  }
  public static parseToUTCDate(value: Date | null): string {
    return moment.utc(value?.toUTCString()).format("YYYY/MM/DD");
  }
  public static rangeOverlap(firstRange: any[], secondRange: any[]) {
    const [firstStartTime, firstEndTime] = firstRange;
    const [secondStartTime, secondEndTime] = secondRange;

    const startFirst = firstStartTime.isBetween(secondStartTime, secondEndTime, "minutes", "()");
    const endFirst = firstEndTime.isBetween(secondStartTime, secondEndTime, "minutes", "()");

    const startLast = secondStartTime.isBetween(firstStartTime, firstEndTime, "minutes", "()");
    const endLast = secondEndTime.isBetween(firstStartTime, firstEndTime, "minutes", "()");

    return startFirst || endFirst || startLast || endLast;
  }
  public static parseToLocaleString(startTime: Date, endTime: Date): string {
    const time = `${moment(startTime).format("HH:mm")}~${moment(endTime).format("HH:mm")}`;
    const date = new Date(startTime);
    const dateToJp = date.toLocaleString("ja", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return `${dateToJp} ${time}`;
  }

  public static toLocalString(date: Date): string {
    const d = new Date(date);
    const formattedDate = d.toLocaleString("ja-JP", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
    return formattedDate.replace(",", "");
  }

  public static toRangeDates = (from: Date, to: Date): Date[] => {
    let start = new Date(from.getTime() + MILLISECONDS_IN_DAY);
    const dates = [];
    while (start.getTime() < to.getTime()) {
      dates.push(start);
      start = new Date(start.getTime() + MILLISECONDS_IN_DAY);
    }
    return [from, ...dates];
  };
}
