import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "../constants/api.router.constants";
import { ILineAccount } from "../typings";
import { AxiosResponse } from "axios";
import { StrictUseAxiosReturn, useAxios } from "@vueuse/integrations/useAxios";

const LineAccountServices = {
  create: async (
    clinicId: number,
    accessToken: string,
    lineUserId: string,
    onSuccess: (account: ILineAccount) => void
  ): Promise<StrictUseAxiosReturn<AxiosResponse<ILineAccount>, AxiosResponse, ILineAccount>> => {
    const { execute } = useAxios<AxiosResponse<ILineAccount>>(
      ApiRouterConstants.API_LINE_ACCOUNTS,
      { method: "POST", data: { access_token: accessToken, clinic_id: clinicId, line_user_id: lineUserId } },
      http,
      {
        immediate: false,
        onSuccess: (account: ILineAccount) => onSuccess(account),
      }
    );
    return await execute();
  },
};

export default LineAccountServices;
