type IMedicalOption = {
  name: string;
  value: string;
};

const MedicalHourOptions: IMedicalOption[] = Array.from({ length: 24 }, (_, i) => {
  const time = `${i.toString().padStart(2, "0")}`;
  return { name: time + "時", value: time };
});

const MedicalMinuteOptions: IMedicalOption[] = Array.from({ length: 12 }, (_, i) => {
  const time = `${(i * 5).toString().padStart(2, "0")}`;
  return { name: time + "分", value: time };
});

export { MedicalHourOptions, MedicalMinuteOptions };
