enum CustomerStatusEnum {
  new = "新規",
  existed = "既存",
  defection = "離反",
}

enum SupportStatusEnum {
  not_compatible = "未対応",
  waiting_for_reply = "返信待ち",
  accepted = "対応済み",
}

export { CustomerStatusEnum, SupportStatusEnum };
