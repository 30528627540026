enum GenderEnum {
  male = "男性",
  female = "女性",
  unknown = "未設定",
}
const AllCustomerStatusesConstants = {
  all: "すべて",
  new: "新患",
  existed: "既存",
  defection: "離反",
  hibernation: "休眠",
  revisited: "再診",
};

enum linkedLineStatus {
  notYet = "紐付けなし",
  linked = "紐付け済み",
}

const CustomerStatusOptions = Object.entries(AllCustomerStatusesConstants).map(([value, name]) => ({
  name,
  value,
}));

export { GenderEnum, linkedLineStatus, CustomerStatusOptions };
