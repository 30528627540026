import { MaybeRefOrGetter } from "@vueuse/core";
import type { Rule, Rules } from "async-validator";
import { REQUIRED_MESSAGE } from "@/common/constants/validate-message.constants";

const rules: MaybeRefOrGetter<Record<string, Rule | Rules>> = {
  name: {
    type: "string",
    required: true,
    transform: value => value.trim(),
    message: REQUIRED_MESSAGE,
  },
  description: {
    type: "string",
    required: true,
    transform: value => value.trim(),
    message: REQUIRED_MESSAGE,
  },
  // timeUnit: [
  //   {
  //     validator: (_, val): boolean => parseInt(val) > 0,
  //     message: REQUIRED_MESSAGE,
  //   },
  // ],
  unitPrice: {
    type: "number",
    required: true,
    min: 0,
    message: REQUIRED_MESSAGE,
  },
};

export default rules;
