import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "../constants/api.router.constants";
import { useAxios } from "@vueuse/integrations/useAxios";

const ClinicServices = {
  fetchDetails: async (clinicId: number) => {
    const { execute } = useAxios(`${ApiRouterConstants.API_CLINIC_DETAILS}/${clinicId}`, { method: "GET" }, http, {
      immediate: false,
    });
    return await execute();
  },
};

export default ClinicServices;
