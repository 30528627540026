import ApiLineConstants from "../../constants/api.line.constants";
import { ApiLineStickerParams } from "@/clinic/typings";

const LineStickerServices = {
  getStickerUrl: (params: ApiLineStickerParams): string => {
    const { sticker_id } = params;
    return `${ApiLineConstants.API_STICKER}/${sticker_id}/android/sticker.png;compress=true`;
  },
};

export default LineStickerServices;
