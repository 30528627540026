import { MaybeRefOrGetter } from "@vueuse/core";
import type { Rule, Rules } from "async-validator";

const rules: MaybeRefOrGetter<Record<string, Rule | Rules>> = {
  email: [
    {
      type: "email",
      required: true,
      message: "入力内容に誤りがあります",
    },
  ],
};
export default rules;
