import { get, useDateFormat } from "@vueuse/core";
import moment from "moment";

export default class DateTimeUtils {
  public static formatDate(startTime: string, endTime: string) {
    const dateStartTime = moment.utc(startTime);
    const dateTime = get(useDateFormat(startTime, "YYYY年MM月DD日"));
    const hoursStart =
      parseInt(dateStartTime.format("HH")) >= 15
        ? parseInt(dateStartTime.format("HH")) - 15
        : parseInt(dateStartTime.format("HH")) + 9;
    const minutesStart = dateStartTime.format("mm");

    let formattedTime = `${hoursStart < 10 ? "0" + hoursStart : hoursStart}:${minutesStart}`;

    if (endTime.trim() != "") {
      const dateEndTime = moment.utc(endTime);
      const hoursEnd =
        parseInt(dateEndTime.format("HH")) >= 15
          ? parseInt(dateEndTime.format("HH")) - 15
          : parseInt(dateEndTime.format("HH")) + 9;
      const minutesEnd = dateEndTime.format("mm");
      formattedTime += ` ~ ${hoursEnd < 10 ? "0" + hoursEnd : hoursEnd}:${minutesEnd}`;
    }

    const formattedDate = `${dateTime} ${formattedTime}`;

    return [formattedDate, formattedTime];
  }

  public static convertMinutesToHoursAndMinutes(minutes: number) {
    if (minutes < 60) {
      return `${minutes}分`;
    }
    const duration = moment.duration(minutes, "minutes");
    const hours = Math.floor(duration.asHours());
    const mins = duration.minutes();

    let result = `${hours}時間`;
    if (mins !== 0) {
      result += `${mins}分`;
    }

    return result;
  }
}
