import http from "@/common/utils/http-common-utils";
import { useAxios } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";

const PostbackMessageTemplatesServices = {
  fetch: async (params: any) => {
    const { execute } = useAxios(
      ApiRouterConstants.API_POSTBACK_MESSAGE_TEMPLATES,
      { method: "GET", params: params },
      http,
      { immediate: false }
    );
    return await execute();
  },
  fetchDetail: async (id: string, params: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_POSTBACK_MESSAGE_TEMPLATES}/${id}`,
      { method: "GET", params: params },
      http,
      { immediate: false }
    );
    return await execute();
  },
  deleteMessageTemplate: async (id: string, params: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_POSTBACK_MESSAGE_TEMPLATES}/${id}`,
      { method: "DELETE", params: params },
      http,
      { immediate: false }
    );
    return await execute();
  },
};

export default PostbackMessageTemplatesServices;
