import { ApiEditNoteReq, ApiListNoteReq, ApiSaveNoteReq, ApiTrashNoteReq, ApiNoteIndexRes } from "@/clinic/typings";
import { EasyUseAxiosReturn, useAxios } from "@vueuse/integrations/useAxios";
import { AxiosResponse } from "axios";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";
import http from "@/common/utils/http-common-utils";

type successCallback = () => void;
type errorCallback = (error: any) => void;

async function executeRequest(
  url: string,
  method: string,
  params?: any,
  data?: any,
  onSuccess?: successCallback,
  onError?: errorCallback
) {
  const { execute } = useAxios<AxiosResponse>(
    url,
    {
      method,
      params,
      data,
    },
    http,
    {
      immediate: false,
      onSuccess: async () => {
        if (onSuccess) onSuccess();
      },
      onError: error => {
        if (onError) onError(error);
      },
    }
  );
  await execute();
}

async function executeResponse(
  url: string,
  method: string,
  params?: any,
  data?: any,
  onSuccess?: successCallback,
  onError?: errorCallback
) {
  const { execute } = useAxios<AxiosResponse>(
    url,
    {
      method,
      params,
      data,
    },
    http,
    {
      immediate: false,
      onSuccess: async () => {
        if (onSuccess) onSuccess();
      },
      onError: error => {
        if (onError) onError(error);
      },
    }
  );
  return await execute();
}

const NoteServices = {
  async listNotes(
    payload: ApiListNoteReq
  ): Promise<EasyUseAxiosReturn<AxiosResponse<ApiNoteIndexRes>, AxiosResponse, ApiNoteIndexRes>> {
    const url = `${ApiRouterConstants.API_CUSTOMERS}/${payload.customer_id}/comments`;
    return await executeResponse(url, "GET", payload);
  },
  async saveNote(params: ApiSaveNoteReq, onSuccess?: successCallback, onError?: errorCallback): Promise<void> {
    const url = `${ApiRouterConstants.API_CUSTOMERS}/${params.customer_id}/comments`;
    await executeRequest(url, "POST", undefined, params, onSuccess, onError);
  },
  async trashNote(params: ApiTrashNoteReq, onSuccess?: successCallback, onError?: errorCallback): Promise<void> {
    const url = `${ApiRouterConstants.API_CUSTOMERS}/${params.customer_id}/comments/${params.id}`;
    await executeRequest(url, "DELETE", params, undefined, onSuccess, onError);
  },
  async editNote(params: ApiEditNoteReq, onSuccess?: successCallback, onError?: errorCallback): Promise<void> {
    const url = `${ApiRouterConstants.API_CUSTOMERS}/${params.customer_id}/comments/${params.id}`;
    await executeRequest(url, "PATCH", params, undefined, onSuccess, onError);
  },
};

export default NoteServices;
