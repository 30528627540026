import { AxiosResponse } from "axios";
import { AnyFn } from "@vueuse/core";
import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";
import {
  ApiBaseReqParams,
  ApiStaffDetailReq,
  ApiStaffIndexRes,
  IStaff,
  ApiStaffDeleteReq,
  ApiStaffDayOffsReq,
  ApiStaffSpecialShiftRegisterReq,
  ApiStaffSpecialShiftsReq,
  ISpecialStaffShift,
  ApiStaffTimeOffsRes,
  ApiStaffTimeOffsCreateReq,
} from "../typings";
import { StrictUseAxiosReturn, useAxios } from "@vueuse/integrations/useAxios";
import RouterUtils from "@/common/utils/router.util";
import RouterConstants from "@/clinic/constants/router.constants";

const StaffServices = {
  getStaffs: async (
    params: ApiBaseReqParams
  ): Promise<StrictUseAxiosReturn<AxiosResponse<ApiStaffIndexRes>, AxiosResponse, ApiStaffIndexRes>> => {
    const { execute } = useAxios<AxiosResponse<ApiStaffIndexRes>>(
      ApiRouterConstants.API_STAFFS,
      { method: "GET", params },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  getStaff: async (
    id: number,
    params: ApiStaffDetailReq
  ): Promise<StrictUseAxiosReturn<AxiosResponse<IStaff>, AxiosResponse, IStaff>> => {
    const { execute } = useAxios<AxiosResponse<IStaff>>(
      `${ApiRouterConstants.API_STAFFS}/${id}`,
      { method: "GET", params },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  trashStaff: async (
    id: number,
    params: ApiStaffDeleteReq,
    onSuccess: () => void,
    onError: (error: any) => void
  ): Promise<void> => {
    const url = `${ApiRouterConstants.API_STAFFS}/${id}`;
    const { execute } = useAxios<AxiosResponse>(url, { method: "DELETE", params }, http, {
      immediate: false,
      onSuccess: async () => {
        if (onSuccess) onSuccess();
        await new Promise(resolve => setTimeout(resolve, 1000));
        RouterUtils.goTo(RouterConstants.STAFF_LIST_ROUTER_NAME);
      },
      onError: error => {
        if (onError) onError(error);
      },
    });
    await execute();
  },

  getDayOffs: async (
    id: number,
    params: ApiStaffDayOffsReq
  ): Promise<StrictUseAxiosReturn<AxiosResponse, AxiosResponse, any>> => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_STAFFS}/${id}/days_off_in_range`,
      { method: "GET", params },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },

  getTimeOffs: async (
    params: ApiStaffSpecialShiftsReq
  ): Promise<StrictUseAxiosReturn<AxiosResponse<ApiStaffTimeOffsRes>, AxiosResponse, ApiStaffTimeOffsRes>> => {
    const { execute } = useAxios<AxiosResponse<ApiStaffTimeOffsRes>, AxiosResponse, ApiStaffTimeOffsRes>(
      `${ApiRouterConstants.API_STAFFS}/time_offs`,
      { method: "GET", params },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },

  createTimeOffs: async (
    payload: ApiStaffTimeOffsCreateReq,
    onSuccess: AnyFn,
    onError: (error: any) => void
  ): Promise<void> => {
    const { execute } = useAxios<void>(
      `${ApiRouterConstants.API_STAFFS}/time_offs`,
      { method: "POST", data: payload as ApiStaffTimeOffsCreateReq },
      http,
      {
        immediate: false,
        onSuccess,
        onError: error => {
          if (onError) onError(error);
        },
      }
    );
    await execute();
  },
};

export default StaffServices;
