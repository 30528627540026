import { AnyFn } from "@vueuse/core";
import { AxiosResponse } from "axios";
import http from "@/common/utils/http-common-utils";
import { useAxios } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";
import { EasyUseAxiosReturn, StrictUseAxiosReturn } from "@vueuse/integrations";
import { ApiShiftTemplatesRes } from "@/clinic/typings/apis/clinic-settings";
import { ApiBaseReq, ApiBaseReqParams, IShiftTemplate } from "@/clinic/typings";

const ShiftTemplateServices = {
  list: async (
    params: ApiBaseReqParams
  ): Promise<EasyUseAxiosReturn<AxiosResponse<ApiShiftTemplatesRes>, AxiosResponse, ApiShiftTemplatesRes>> => {
    const { execute } = useAxios<AxiosResponse<ApiShiftTemplatesRes>>(
      ApiRouterConstants.API_CLINIC_SHIFT_TEMPLATES,
      { method: "GET", params },
      http,
      { immediate: false }
    );
    return await execute();
  },
  show: async (
    id: number,
    params: ApiBaseReq
  ): Promise<EasyUseAxiosReturn<AxiosResponse<IShiftTemplate>, AxiosResponse, IShiftTemplate>> => {
    const { execute } = useAxios<AxiosResponse<IShiftTemplate>>(
      `${ApiRouterConstants.API_CLINIC_SHIFT_TEMPLATES}/${id}`,
      { method: "GET", params },
      http,
      { immediate: false }
    );
    return await execute();
  },
  save: async (
    method: string,
    params: ApiBaseReq,
    data: IShiftTemplate,
    onSuccess: AnyFn,
    onError: AnyFn
  ): Promise<StrictUseAxiosReturn<AxiosResponse<IShiftTemplate>, AxiosResponse, IShiftTemplate>> => {
    const id = method === "PUT" ? `/${data.id}` : "";
    const { execute } = useAxios<AxiosResponse<IShiftTemplate>>(
      `${ApiRouterConstants.API_CLINIC_SHIFT_TEMPLATES}${id}`,
      { method: method, data, params },
      http,
      { immediate: false, onSuccess, onError }
    );
    return await execute();
  },
  delete: async (
    id: number,
    params: ApiBaseReq,
    onSuccess: AnyFn,
    onError: AnyFn
  ): Promise<StrictUseAxiosReturn<AxiosResponse<IShiftTemplate>, AxiosResponse, IShiftTemplate>> => {
    const { execute } = useAxios<AxiosResponse<IShiftTemplate>>(
      `${ApiRouterConstants.API_CLINIC_SHIFT_TEMPLATES}/${id}`,
      { method: "DELETE", params },
      http,
      { immediate: false, onSuccess, onError }
    );
    return await execute();
  },
};

export default ShiftTemplateServices;
