type IMedicalMenuOption = {
  name: string;
  value: string;
};

const MedicalMenuHourOptions: IMedicalMenuOption[] = Array.from({ length: 24 }, (_, i) => {
  const time = `${i.toString().padStart(2, "0")}`;
  return { name: time + "時", value: time };
});

const MedicalMenuMinuteOptions: IMedicalMenuOption[] = Array.from({ length: 12 }, (_, i) => {
  const time = `${(i * 5).toString().padStart(2, "0")}`;
  return { name: time + "分", value: time };
});

export { MedicalMenuHourOptions, MedicalMenuMinuteOptions };
