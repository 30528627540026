type ICampaignMessage = {
  name: string;
  value: number;
};

const CampaignMessageOptions: ICampaignMessage[] = Array.from({ length: 4 }, (_, i) => {
  const number = `${(i + 2).toString().padStart(2)}`;
  return { name: number + "回", value: parseInt(number) };
});

export { CampaignMessageOptions };
