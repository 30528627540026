import { AxiosResponse } from "axios";
import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "../constants/api.router.constants";
import { ApiBaseReq, ApiTagCreateReq, ApiTagIndexParams, ITag } from "@/clinic/typings";
import { useAxios, EasyUseAxiosReturn } from "@vueuse/integrations/useAxios";
import { AnyFn, Fn } from "@vueuse/core";

const TagService = {
  getTags: async (
    payload: ApiTagIndexParams,
    onSuccess: Fn,
    onError: AnyFn
  ): Promise<EasyUseAxiosReturn<AxiosResponse<ITag[]>, AxiosResponse, ITag[]>> => {
    const { execute } = useAxios<AxiosResponse<ITag[]>, AxiosResponse, ITag[]>(
      ApiRouterConstants.API_TAGS,
      {
        method: "GET",
        params: payload,
      },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute();
  },
  createTag: async (
    params: ApiBaseReq,
    payload: ApiTagCreateReq,
    onSuccess: (tag: ITag) => void,
    onError: (error: any) => void
  ): Promise<void> => {
    const { execute } = useAxios<AxiosResponse<ITag>>(
      ApiRouterConstants.API_TAGS,
      { method: "POST", params, data: payload },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    await execute();
  },
};

export default TagService;
