import type { Rules } from "async-validator";

const rules: Rules = {
  username: {
    type: "string",
    required: true,
    transform(value) {
      return value.trim();
    },
    message: "入力内容に誤りがあります",
  },
  password: {
    type: "string",
    required: true,
    message: "入力内容に誤りがあります",
  },
};
export default rules;
