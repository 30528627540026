import axios from "axios";
import { AxiosResponse } from "axios";
import http from "../../common/utils/http-common-utils";
import ApiRouterConstants from "../constants/api.router.constants";
import CustomerApiRouterConstants from "@/common/constants/api.router.constants";
import {
  ApiBaseReqParams,
  ApiCustomerIndexRes,
  ApiCustomerNewReq,
  ApiCustomerSaveCommentReq,
  ApiCustomerSaveTagsReq,
  ApiCustomerShowReq,
  ICustomer,
  ITag,
} from "../typings";
import { useAxios, EasyUseAxiosReturn } from "@vueuse/integrations/useAxios";
import { useCookies } from "@vueuse/integrations/useCookies";
import { AnyFn, Fn } from "@vueuse/core";
import RouterUtils from "@/common/utils/router.util";
import RouterConstants from "@/clinic/constants/router.constants";

const CustomerServices = {
  exportCustomers: async (payload: ApiBaseReqParams) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_CUSTOMERS}/export`,
      {
        method: "GET",
      },
      http,
      {
        immediate: false,
      }
    );
    return await execute({ params: payload });
  },
  importCustomers: async (clinicId: number, file: any, onSuccess: () => void, onError: () => void) => {
    const { get: getCookie } = useCookies();
    const token = getCookie("access_token");
    const formData = new FormData();
    formData.append("import_file", file);

    axios
      .post(`${ApiRouterConstants.API_CUSTOMERS}/import?clinic_id=${clinicId}`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      })
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      });
  },
  getCustomers: async (
    payload: ApiBaseReqParams,
    onSuccess: Fn,
    onError: AnyFn
  ): Promise<EasyUseAxiosReturn<AxiosResponse<ApiCustomerIndexRes>, AxiosResponse, ApiCustomerIndexRes>> => {
    const { execute } = useAxios<AxiosResponse<ApiCustomerIndexRes>, AxiosResponse, ApiCustomerIndexRes>(
      ApiRouterConstants.API_CUSTOMERS,
      {
        method: "GET",
      },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute({ params: payload });
  },
  getCustomer: async (
    payload: ApiCustomerShowReq
  ): Promise<EasyUseAxiosReturn<AxiosResponse<ICustomer>, AxiosResponse, ICustomer>> => {
    const { id } = payload;
    const { execute } = useAxios<AxiosResponse<ICustomer>, AxiosResponse, ICustomer>(
      `${ApiRouterConstants.API_CUSTOMERS}/${id}`,
      {
        method: "GET",
        params: payload,
      },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  saveTags: async (
    payload: ApiCustomerSaveTagsReq,
    onSuccess: AnyFn,
    onError: AnyFn
  ): Promise<EasyUseAxiosReturn<AxiosResponse<ITag[]>, AxiosResponse, ITag[]>> => {
    const { id, clinic_id } = payload;
    type ApiSaveCommentReq = Omit<ApiCustomerSaveCommentReq, "id" | "clinic_id">;
    const { execute } = useAxios<AxiosResponse<ITag[], ApiSaveCommentReq>>(
      `${ApiRouterConstants.API_CUSTOMERS}/${id}/tags`,
      {
        method: "POST",
        params: { clinic_id },
        data: payload as ApiCustomerSaveTagsReq,
      },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute();
  },
  saveCustomer: async (
    saveParams: ApiCustomerShowReq,
    payload: ApiCustomerNewReq,
    onSuccess: AnyFn,
    onError: AnyFn
  ): Promise<void> => {
    const { id, ...params } = saveParams;
    const [method, url] = id
      ? ["PATCH", `${ApiRouterConstants.API_CUSTOMERS}/${id}`]
      : ["POST", ApiRouterConstants.API_CUSTOMERS];
    const { execute } = useAxios<AxiosResponse<ICustomer, ApiCustomerNewReq>>(
      url,
      { method, params, data: payload as ApiCustomerNewReq },
      http,
      {
        immediate: false,
        onSuccess: (response: any) => onSuccess(response),
        onError: (error: any) => onError(error),
      }
    );
    await execute();
  },
  trashCustomer: async (
    params: ApiCustomerShowReq,
    onSuccess: () => void,
    onError: (error: any) => void
  ): Promise<void> => {
    const { id } = params;
    const url = `${ApiRouterConstants.API_CUSTOMERS}/${id}`;
    const { execute } = useAxios<AxiosResponse>(url, { method: "DELETE", params }, http, {
      immediate: false,
      onSuccess: async () => {
        if (onSuccess) onSuccess();
        await new Promise(resolve => setTimeout(resolve, 1000));
        RouterUtils.goTo(RouterConstants.CUSTOMER_ROUTER_NAME);
      },
      onError: error => {
        if (onError) onError(error);
      },
    });
    await execute();
  },

  getBookingCustomers: async (
    payload: ApiBaseReqParams,
    onSuccess: Fn,
    onError: AnyFn
  ): Promise<EasyUseAxiosReturn<AxiosResponse<ApiCustomerIndexRes>, AxiosResponse, ApiCustomerIndexRes>> => {
    const { execute } = useAxios<AxiosResponse<ApiCustomerIndexRes>, AxiosResponse, ApiCustomerIndexRes>(
      ApiRouterConstants.API_BOOKING_CUSTOMERS,
      {
        method: "GET",
      },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute({ params: payload });
  },

  getBookingDetail: async (bookingId: any, params: any) => {
    const { execute } = useAxios(
      `${CustomerApiRouterConstants.API_BOOKING}/${bookingId}`,
      { method: "GET", params: params },
      http,
      { immediate: false }
    );
    return await execute();
  },
};

export default CustomerServices;
