import { ApiBookingSettingRequest, ApiBookingSettingResponse, IBookingSetting } from "../typings";
import { AxiosResponse } from "axios";
import { useAxios, EasyUseAxiosReturn } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "../constants/api.router.constants";
import http from "../../common/utils/http-common-utils";

const BookingSettingService = {
  getBookingSetting: async (
    clinicId: number,
    onSuccess: (response: any) => void,
    onError: (error: any) => void
  ): Promise<
    EasyUseAxiosReturn<AxiosResponse<ApiBookingSettingResponse>, AxiosResponse, ApiBookingSettingResponse>
  > => {
    const { execute } = useAxios<AxiosResponse<ApiBookingSettingResponse>, AxiosResponse, ApiBookingSettingResponse>(
      ApiRouterConstants.API_BOOKING_SETTING,
      {
        method: "GET",
      },
      http,
      {
        immediate: false,
        onSuccess: (response: any) => {
          onSuccess(response);
        },
        onError: (error: any) => {
          onError(error.response?.data?.errors);
        },
      }
    );
    return await execute({ params: { clinic_id: clinicId } });
  },
  updateBookingSetting: async (
    payload: ApiBookingSettingRequest,
    clinicId: number,
    onSuccess: () => void,
    onError: (error: any) => void
  ): Promise<EasyUseAxiosReturn<AxiosResponse<IBookingSetting>, AxiosResponse, IBookingSetting>> => {
    const { execute } = useAxios<AxiosResponse<IBookingSetting, ApiBookingSettingResponse>>(
      ApiRouterConstants.API_BOOKING_SETTING,
      {
        method: "PATCH",
        data: payload,
      },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: (error: any) => {
          onError(error.response?.data?.error);
        },
      }
    );
    return await execute({ params: { clinic_id: clinicId } });
  },
};

export default BookingSettingService;
