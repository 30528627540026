import { StrictUseAxiosReturn, useAxios } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "../constants/api.router.constants";
import {
  ApiBookingIndexReq,
  ApiBookingShowReq,
  ApiBookingIndexRes,
  IBooking,
  ApiCommentBookingReq,
  ApiBookingSaveReq,
  ApiSaveStatusBookingReq,
  ApiBookingExportReq,
  ApiListBookingHistoryReq,
} from "../typings";
import { AxiosResponse } from "axios";
import http from "@/common/utils/http-common-utils";
import { AnyFn } from "@vueuse/core";

const BookingServices = {
  getBookings: async (
    params: ApiBookingIndexReq
  ): Promise<StrictUseAxiosReturn<AxiosResponse<ApiBookingIndexRes>, AxiosResponse, ApiBookingIndexRes>> => {
    const { execute } = useAxios<AxiosResponse<ApiBookingIndexRes>>(
      ApiRouterConstants.API_BOOKINGS,
      { method: "GET", params },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  listBookings: async (
    params: ApiListBookingHistoryReq
  ): Promise<StrictUseAxiosReturn<AxiosResponse<ApiBookingIndexRes>, AxiosResponse, ApiBookingIndexRes>> => {
    const { execute } = useAxios<AxiosResponse<ApiBookingIndexRes>>(
      `${ApiRouterConstants.API_BOOKINGS}/list`,
      { method: "GET", params },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  getBooking: async (
    params: ApiBookingShowReq
  ): Promise<StrictUseAxiosReturn<AxiosResponse<IBooking>, AxiosResponse, IBooking>> => {
    const { id } = params;
    const { execute } = useAxios<AxiosResponse<IBooking>>(
      `${ApiRouterConstants.API_BOOKINGS}/${id}`,
      { method: "GET", params },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  saveComment: async (params: ApiBookingShowReq, payload: ApiCommentBookingReq): Promise<void> => {
    const { id } = params;
    const { execute } = useAxios<AxiosResponse<IBooking>>(
      `${ApiRouterConstants.API_BOOKINGS}/${id}/comments`,
      { method: "PATCH", params, data: payload },
      http,
      { immediate: false }
    );
    await execute();
  },
  saveBooking: async (
    params: ApiBookingShowReq,
    payload: ApiBookingSaveReq,
    onSuccess: AnyFn,
    onError: AnyFn
  ): Promise<void> => {
    const { id } = params;
    const [idUrl, method] = !id ? ["", "POST"] : [`/${id}`, "PUT"];
    const { execute } = useAxios<AxiosResponse<IBooking>>(
      `${ApiRouterConstants.API_BOOKINGS}${idUrl}`,
      { method, params, data: payload },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    await execute();
  },
  saveStatus: async (
    params: ApiBookingShowReq,
    payload: ApiSaveStatusBookingReq,
    onSuccess: AnyFn,
    onError: AnyFn
  ): Promise<void> => {
    const { id } = params;
    const { execute } = useAxios<AxiosResponse<IBooking>>(
      `${ApiRouterConstants.API_BOOKINGS}/${id}/change_status`,
      { method: "PATCH", params, data: payload },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    await execute();
  },
  exportBookings: async (payload: ApiBookingExportReq) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_BOOKINGS}/export`,
      {
        method: "GET",
      },
      http,
      {
        immediate: false,
      }
    );
    return await execute({ params: payload });
  },
  deleteBooking: async (bookingId: number, clinicId: number, resource: string, onSuccess: AnyFn, onError: AnyFn) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_BOOKINGS}/${bookingId}?clinic_id=${clinicId}&resource=${resource}`,
      {
        method: "DELETE",
      },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute();
  },
  approveRequest: async (bookingId: number, requestId: number, clinicId: number, onSuccess: AnyFn, onError: AnyFn) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_REQUEST_BOOKINGS}/${bookingId}/approve?clinic_id=${clinicId}`,
      {
        method: "PATCH",
      },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute({ params: { request_id: requestId } });
  },
  saveBatchBooking: async (
    params: ApiBookingShowReq,
    payload: ApiBookingSaveReq,
    onSuccess: AnyFn,
    onError: AnyFn
  ): Promise<void> => {
    const { id } = params;
    const [idUrl, method] = !id ? ["/batch_create", "POST"] : ["/batch_update", "PUT"];
    const { execute } = useAxios<AxiosResponse<IBooking>>(
      `${ApiRouterConstants.API_BOOKINGS}${idUrl}`,
      { method, params, data: payload },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    await execute();
  },
  saveBatchStatus: async (
    params: any,
    payload: ApiSaveStatusBookingReq,
    onSuccess: AnyFn,
    onError: AnyFn
  ): Promise<void> => {
    const { id } = params;
    const { execute } = useAxios<AxiosResponse<IBooking>>(
      `${ApiRouterConstants.API_BOOKINGS}/${id}/batch_change_status`,
      { method: "PATCH", params, data: payload },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    await execute();
  },
  confirmHotpepper: async (params: ApiBookingShowReq, onSuccess: AnyFn): Promise<void> => {
    const { id } = params;
    const { execute } = useAxios<AxiosResponse<IBooking>>(
      `${ApiRouterConstants.API_BOOKINGS}/${id}/hotpepper/confirmations`,
      { method: "PATCH", params },
      http,
      {
        immediate: false,
        onSuccess,
      }
    );
    await execute();
  },
};

export default BookingServices;
