import { AxiosResponse } from "axios";
import { EasyUseAxiosReturn, useAxios } from "@vueuse/integrations/useAxios";
import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";
import { ApiLiveChatMessageParams, IDirectMessage, ApiLiveChatMessageShowParams } from "../typings";
import { AnyFn } from "@vueuse/core";

const DirectMessageServices = {
  updateMessageAsRead: async (messageId: number, clinicId: number, onSuccess: () => void) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_LIVE_CHAT_MESSAGES}/${messageId}?clinic_id=${clinicId}`,
      { method: "PUT", params: { read: true } },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
      }
    );
    return await execute();
  },
  searchMessages: async (
    keyword: string,
    clinicId: number,
    lineUserId: number,
    page: number,
    rows: number,
    onSuccess: AnyFn,
    onError: AnyFn
  ) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_LIVE_CHAT_MESSAGES}/search?clinic_id=${clinicId}`,
      { method: "GET", params: { line_user_id: lineUserId, keyword: keyword, page: page, rows: rows } },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute();
  },
  getMessages: async (
    params: ApiLiveChatMessageParams,
    onSuccess: AnyFn,
    onError: AnyFn
  ): Promise<EasyUseAxiosReturn<AxiosResponse<IDirectMessage[]>, AxiosResponse, IDirectMessage[]>> => {
    const { execute } = useAxios<AxiosResponse<IDirectMessage[]>, AxiosResponse, IDirectMessage[]>(
      ApiRouterConstants.API_LIVE_CHAT_MESSAGES,
      { method: "GET", params },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute();
  },
  getMessage: async (
    showParams: ApiLiveChatMessageShowParams,
    onSuccess: AnyFn,
    onError: AnyFn
  ): Promise<EasyUseAxiosReturn<AxiosResponse<IDirectMessage>, AxiosResponse, IDirectMessage>> => {
    const { id, ...params } = showParams;
    const { execute } = useAxios<AxiosResponse<IDirectMessage>, AxiosResponse, IDirectMessage>(
      `${ApiRouterConstants.API_LIVE_CHAT_MESSAGES}/${id}`,
      { method: "GET", params },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute();
  },
};

export default DirectMessageServices;
