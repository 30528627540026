import { useAxios } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";
import http from "@/common/utils/http-common-utils";
import type { ApiLiveMessageTemplateIndexReq, ApiLiveMessageTemplateReq } from "@/clinic/typings";

const LiveMessageTemplateServices = {
  fetchTemplates: async (
    params: ApiLiveMessageTemplateIndexReq,
    onSuccess: () => void,
    onError: () => void,
    onFinish: () => void
  ) => {
    const { execute } = useAxios(
      ApiRouterConstants.API_LIVE_MESSAGE_TEMPLATES,
      { method: "GET", params: params },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          onError();
        },
        onFinish: () => {
          onFinish();
        },
      }
    );
    return await execute();
  },
  deleteTemplate: async (params: ApiLiveMessageTemplateReq, onSuccess: () => void, onError: () => void) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_LIVE_MESSAGE_TEMPLATES}/${params.id}`,
      { method: "DELETE", params: { clinic_id: params.clinic_id } },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          onError();
        },
      }
    );
    return await execute();
  },
  fetchTemplate: async (params: ApiLiveMessageTemplateReq, onSuccess: () => void, onError: () => void) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_LIVE_MESSAGE_TEMPLATES}/${params.id}`,
      { method: "GET", params: { clinic_id: params.clinic_id } },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          onError();
        },
      }
    );
    return await execute();
  },
  saveTemplate: async (formObject: any, onSuccess: () => void, onError: () => void) => {
    const method = formObject.id == 0 ? "POST" : "PUT";
    const url =
      formObject.id == 0
        ? ApiRouterConstants.API_LIVE_MESSAGE_TEMPLATES
        : `${ApiRouterConstants.API_LIVE_MESSAGE_TEMPLATES}/${formObject.id}`;
    const { execute } = useAxios(url, { method: method }, http, {
      immediate: false,
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        onError();
      },
    });
    return await execute({ params: formObject });
  },
  useTemplate: async (params: ApiLiveMessageTemplateReq, onSuccess: () => void, onError: () => void) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_LIVE_MESSAGE_TEMPLATES}/${params.id}/use`,
      { method: "PATCH", params: { clinic_id: params.clinic_id } },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          onError();
        },
      }
    );
    return await execute();
  },
};

export default LiveMessageTemplateServices;
