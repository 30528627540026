import { AxiosResponse } from "axios";
import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "../../constants/api.router.constants";
import { ApiClinicDailyRoutineResonponse, ApiApplyPrevMonthRequest } from "@/clinic/typings";
import { useAxios, EasyUseAxiosReturn } from "@vueuse/integrations/useAxios";

const ClinicApplySettingPrevMonthService = {
  updateClinicRoutineDaily: async (
    payload: ApiApplyPrevMonthRequest,
    clinicId: number,
    onSuccess: () => void,
    onError: (error: any) => void
  ): Promise<
    EasyUseAxiosReturn<AxiosResponse<ApiClinicDailyRoutineResonponse>, AxiosResponse, ApiClinicDailyRoutineResonponse>
  > => {
    const { execute } = useAxios<AxiosResponse<ApiClinicDailyRoutineResonponse>, AxiosResponse>(
      ApiRouterConstants.API_APPLY_PREV_MONTH,
      {
        method: "PATCH",
      },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: (error: any) => {
          onError(error.response?.data?.errors);
        },
      }
    );
    return await execute({ data: payload, params: { clinic_id: clinicId } });
  },
};

export default ClinicApplySettingPrevMonthService;
