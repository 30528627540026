enum CategoryEnum {
  all_user = "全て",
  visited = "来店済",
  reserved = "予約済",
  unreserved = "未予約",
}

type ICategoryOptions = {
  name: string;
  value: string;
};

enum StatusEnum {
  delivered = "配信終了",
  undelivered = "未配信",
}

const CategoryOptions: ICategoryOptions[] = Object.entries(CategoryEnum).map(([value, name]) => ({
  name,
  value,
}));

export { CategoryEnum, StatusEnum, CategoryOptions };
