import ApiLineConstants from "../../constants/api.line.constants";
import { ApiLineEmojiParams } from "@/clinic/typings";

const LineEmojiServices = {
  getEmojiUrl: (params: ApiLineEmojiParams): string => {
    const { product_id, emoji_id } = params;
    return `${ApiLineConstants.API_EMOJI}/${product_id}/android/${emoji_id}.png`;
  },
};

export default LineEmojiServices;
