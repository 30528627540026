import axios from "axios";
import ApiRouterConstants from "../constants/api.router.constants";

const defaultState = () => {
  return {
    id: 0,
    name: "",
    email: "",
    gender: "",
    birthday: "",
    lineUserId: "",
  };
};

const Customer = {
  namespaced: true,
  state: defaultState,
  mutations: {
    setId: (state: any, id: number) => {
      state.id = id;
    },
    setEmail: (state: any, email: string) => {
      state.email = email;
    },
    setName: (state: any, name: string) => {
      state.name = name;
    },
    setLineUserId: (state: any, lineUserId: string) => {
      state.lineUserId = lineUserId;
    },
  },
  actions: {
    initCustomer({ state, getters }: any, successCallback: any) {
      const options = {
        method: "POST",
        url: ApiRouterConstants.API_CUSTOMERS,
        params: {
          name: getters.name,
          email: getters.email,
          line_user_id: getters.lineUserId,
        },
        headers: { Accept: "application/json" },
      };

      axios.request(options).then(response => {
        const customer = response.data;

        state.id = customer.id;
        state.email = customer.email;
        state.lineUserId = customer.line_user_id;
        successCallback();
      });
    },
  },
  getters: {
    id: (state: any) => {
      return state.id;
    },
    email: (state: any) => {
      return state.email;
    },
    name: (state: any) => {
      return state.name;
    },
    lineUserId: (state: any) => {
      return state.lineUserId;
    },
  },
};

export default Customer;
