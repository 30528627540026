import { useStore } from "vuex";
import RouterUtils from "@/common/utils/router.util";
import RouterConstants from "../constants/router.constants";

const ValidateBookingSteps = () => {
  const store = useStore();

  if (store.getters["booking/bookingId"] != 0) {
    return;
  } else if (store.getters["booking/selectedStaffId"] == 0) {
    RouterUtils.goTo(RouterConstants.BOOKING_ROUTER_NAME);
  } else if (store.getters["booking/selectedMenuCategoryId"] == 0) {
    RouterUtils.goTo(RouterConstants.BOOKING_MENU_ROUTER_NAME);
  } else if (store.getters["booking/selectedMenu"].id == 0) {
    RouterUtils.goTo(RouterConstants.BOOKING_MENU_ROUTER_NAME);
  } else if (Object.keys(store.getters["booking/selectedTime"]).length == 0) {
    RouterUtils.goTo(RouterConstants.BOOKING_CALENDAR_ROUTER_NAME);
  } else if (
    store.getters["booking/customerInfo"].familyName == "" ||
    store.getters["booking/customerInfo"].tel == ""
  ) {
    RouterUtils.goTo(RouterConstants.PERSONAL_INFO_ROUTER_NAME);
  }
};

export default ValidateBookingSteps;
