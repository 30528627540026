import { MaybeRefOrGetter } from "@vueuse/core";
import type { Rule, Rules } from "async-validator";

const rules: MaybeRefOrGetter<Record<string, Rule | Rules>> = {
  subject: {
    type: "string",
    required: true,
    transform: value => value.trim(),
    message: "件名は必須です。",
  },
  name: {
    type: "string",
    required: true,
    transform: value => value.trim(),
    message: "担当者名は必須です。",
  },
  phone_number: {
    type: "string",
    required: true,
    transform: value => value.trim(),
    message: "電話番号は必須です。",
  },
  email: {
    type: "email",
    required: true,
    transform: value => value.trim(),
    message: "メールアドレスは必須です。",
  },
  content: {
    type: "string",
    required: true,
    transform: value => value.trim(),
    message: "お問合せ内容は必須です。",
  },
};
export default rules;
