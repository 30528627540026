import { useAxios } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";
import http from "@/common/utils/http-common-utils";
import type { ApiManualTagIndexReq, ApiManualTagReq } from "@/clinic/typings";

const ManualTagServices = {
  fetchTags: async (params: ApiManualTagIndexReq, onSuccess: () => void, onError: () => void, onFinish: () => void) => {
    const { execute } = useAxios(ApiRouterConstants.API_MANUAL_TAGS, { method: "GET", params: params }, http, {
      immediate: false,
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        onError();
      },
      onFinish: () => {
        onFinish();
      },
    });
    return await execute();
  },
  deleteTag: async (params: ApiManualTagReq, onSuccess: () => void, onError: () => void) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_MANUAL_TAGS}/${params.id}`,
      { method: "DELETE", params: { clinic_id: params.clinic_id } },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          onError();
        },
      }
    );
    return await execute();
  },
  saveTag: async (formObject: any, onSuccess: () => void, onError: (errors: any) => void) => {
    const method = formObject.id ? "PATCH" : "POST";
    const url = formObject.id
      ? `${ApiRouterConstants.API_MANUAL_TAGS}/${formObject.id}`
      : ApiRouterConstants.API_MANUAL_TAGS;
    const { execute } = useAxios(url, { method: method }, http, {
      immediate: false,
      onSuccess: () => {
        onSuccess();
      },
      onError: (errors: any) => {
        onError(errors.response?.data);
      },
    });
    return await execute({ params: formObject });
  },
};

export default ManualTagServices;
