export const DEFAULT_LOGO_URL = "/images/logo-header.png";
export const GROUP_NAMES_EMOJI = {
  smileys_people: "笑顔 & 人々",
  animals_nature: "動物 & 自然",
  food_drink: "食べ物 & 飲み物",
  activities: "活動",
  travel_places: "旅行と場所",
  objects: "オブジェクト",
  symbols: "記号",
  flags: "旗",
};
export const PLACEHOLDER_EMOJI = {
  placeholder: "絵文字検索",
};
export const MAX_NOTE = 4;
export const MAX_SPECIAL_NOTE = 3;
export const CALCULATOR_HEIGHT_DIRECT_MESSAGE_NOTE = 20;
export const CALCULATOR_HEIGHT_CUSTOMER_NOTE = 8;
export const PLACEHOLDER_SELECT = "選択してください";
export const PLACEHOLDER_PERIOD = "期間指定してください";
export const CUSTOMER_ID_NAME_PLACEHOLDER_SELECT = "顧客ID、お名前";
export const CUSTOMER_ID_NAME_NAME_KANA_PHONE_PLACEHOLDER_SELECT = "顧客ID、氏名、カナ、電話番号";
export const WeekDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
export const LINK_GOOGLE_MAP = "https://www.google.com/maps?q=";
