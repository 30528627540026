import RouterConstants from "./app.constants";

export default class ApiRouterConstants {
  static API_CUSTOMERS = `${RouterConstants.API_URL}api/v1/customers`;
  static API_STAFF = `${RouterConstants.API_URL}api/v1/customers/staffs`;
  static API_MENU = `${RouterConstants.API_URL}api/v1/customers/medical_menus`;
  static API_MENU_OPTION = `${RouterConstants.API_URL}api/v1/customers/medical_options`;
  static API_BOOKING = `${RouterConstants.API_URL}api/v1/customers/bookings`;
  static API_ANALYSIS = `${RouterConstants.API_URL}api/v1/clinics/analysis`;
}
