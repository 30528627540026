import { ApiStaffDistributionPayload, IStaff } from "@/clinic/typings";
import ApiRouterConstants from "@/common/constants/api.router.constants";
import http from "@/common/utils/http-common-utils";
import { StrictUseAxiosReturn, useAxios } from "@vueuse/integrations";
import { AxiosResponse } from "axios";

const StaffDistributionService = {
  getStaff: async (
    payload: ApiStaffDistributionPayload
  ): Promise<StrictUseAxiosReturn<AxiosResponse<IStaff>, AxiosResponse, IStaff>> => {
    const { execute } = useAxios<AxiosResponse<IStaff>>(
      `${ApiRouterConstants.API_STAFF}/distribute`,
      { method: "POST", data: payload },
      http,
      { immediate: false }
    );
    return await execute();
  },
};

export default StaffDistributionService;
