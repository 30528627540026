const TotalVisitOptions = [
  { label: "全て", value: "all" },
  { label: "1回", value: "1-1" },
  { label: "2~3回", value: "2-3" },
  { label: "4~5回", value: "4-5" },
  { label: "6~10回", value: "6-10" },
  { label: "11~20回", value: "11-20" },
  { label: "21~30回", value: "21-30" },
  { label: "31回以上", value: "31-" },
];

const TotalVisitsInMonthOptions = [
  { label: "当月履歴すべて", value: "all" },
  { label: "月内1回", value: "1" },
  { label: "月内2回", value: "2" },
  { label: "月内3回", value: "3" },
  { label: "月内4回", value: "4" },
  { label: "月内5回", value: "5" },
  { label: "月内6回以上", value: "6-" },
];

const AgeFilterOptions = [
  { label: "すべて", value: "all" },
  { label: "10歳", value: 10 },
  { label: "20歳", value: 20 },
  { label: "30歳", value: 30 },
  { label: "40歳", value: 40 },
  { label: "50歳", value: 50 },
  { label: "60歳", value: 60 },
  { label: "70歳", value: 70 },
  { label: "80歳以上", value: 80 },
];

const FilterTimeOptions = [
  { label: "すべて", value: 0 },
  { label: "1週間以内", value: 7 },
  { label: "2週間以内", value: 14 },
  { label: "1ヶ月以内", value: 30 },
  { label: "3ヶ月以内", value: 90 },
  { label: "6ヶ月以内", value: 180 },
  { label: "1年以内", value: 365 },
];
export { TotalVisitOptions, TotalVisitsInMonthOptions, AgeFilterOptions, FilterTimeOptions };
