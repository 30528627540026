const data = {
  sunday: "日曜",
  monday: "月曜",
  tuesday: "火曜",
  wednesday: "水曜",
  thursday: "木曜",
  friday: "金曜",
  saturday: "土曜",
};
enum dayOfWeek {
  sunday = "日曜",
  monday = "月曜",
  tuesday = "火曜",
  wednesday = "水曜",
  thursday = "木曜",
  friday = "金曜",
  saturday = "土曜",

  specified = "日時指定",
}
enum dayOfWeekShort {
  Sunday = "日",
  Monday = "月",
  Tuesday = "火",
  Wednesday = "水",
  Thursday = "木",
  Friday = "金",
  Saturday = "土",
}

const dayOfWeekOptions = [
  { label: "日", value: 0 },
  { label: "月", value: 1 },
  { label: "火", value: 2 },
  { label: "水", value: 3 },
  { label: "木", value: 4 },
  { label: "金", value: 5 },
  { label: "土", value: 6 },
];
export { dayOfWeek, data, dayOfWeekShort, dayOfWeekOptions };
