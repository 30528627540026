import { AxiosResponse } from "axios";
import http from "@/common/utils/http-common-utils";
import formDataHttp from "@/common/utils/form-data-http-common-utils";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";
import { ApiBaseReqParams, ApiMenuIndexRes, ApiMenuSaveReq, ApiMenuShowReq, IMenu, ApiMenuTrashReq } from "../typings";
import { StrictUseAxiosReturn, useAxios } from "@vueuse/integrations/useAxios";
import { AnyFn } from "@vueuse/core";
import RouterUtils from "@/common/utils/router.util";
import RouterConstants from "@/clinic/constants/router.constants";

const MenuServices = {
  getBookingMenus: async (
    params: ApiBaseReqParams
  ): Promise<StrictUseAxiosReturn<AxiosResponse<ApiMenuIndexRes>, AxiosResponse, ApiMenuIndexRes>> => {
    const { execute } = useAxios<AxiosResponse<ApiMenuIndexRes>>(
      ApiRouterConstants.API_MENUS,
      { method: "GET", params },
      http,
      { immediate: false }
    );
    return await execute();
  },
  getMenus: async (
    params: ApiBaseReqParams
  ): Promise<StrictUseAxiosReturn<AxiosResponse<ApiMenuIndexRes>, AxiosResponse, ApiMenuIndexRes>> => {
    const { execute } = useAxios<AxiosResponse<ApiMenuIndexRes>>(
      ApiRouterConstants.API_MENUS,
      { method: "GET", params },
      http,
      { immediate: false }
    );
    return await execute();
  },
  getMenu: async (
    params: ApiMenuShowReq
  ): Promise<StrictUseAxiosReturn<AxiosResponse<IMenu>, AxiosResponse, IMenu>> => {
    const { id } = params;
    const { execute } = useAxios<AxiosResponse<IMenu>>(
      `${ApiRouterConstants.API_MENUS}/${id}`,
      { method: "GET", params },
      http,
      { immediate: false }
    );
    return await execute();
  },
  saveMenu: async (
    params: ApiMenuShowReq,
    payload: ApiMenuSaveReq,
    onSuccess: AnyFn,
    onError: AnyFn
  ): Promise<StrictUseAxiosReturn<AxiosResponse<IMenu>, AxiosResponse, IMenu>> => {
    const { id } = params;
    const [method, idUrl] = !id ? ["POST", ""] : ["PATCH", `/${id}`];

    const { execute } = useAxios<AxiosResponse<IMenu>>(
      `${ApiRouterConstants.API_MENUS}${idUrl}`,
      { method, params, data: payload },
      formDataHttp,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute();
  },
  trashMenu: async (
    id: number,
    params: ApiMenuTrashReq,
    onSuccess: () => void,
    onError: (error: any) => void
  ): Promise<void> => {
    const url = `${ApiRouterConstants.API_MENUS}/${id}`;
    const { execute } = useAxios<ApiMenuTrashReq>(url, { method: "DELETE", params }, http, {
      immediate: false,
      onSuccess: async () => {
        if (onSuccess) onSuccess();
        await new Promise(resolve => setTimeout(resolve, 1000));
        RouterUtils.goTo(RouterConstants.MENU_ROUTER_NAME);
      },
      onError: error => {
        if (onError) onError(error);
      },
    });
    await execute();
  },

  getBookingMedicalMenus: async (
    params: any
  ): Promise<StrictUseAxiosReturn<AxiosResponse<ApiMenuIndexRes>, AxiosResponse, ApiMenuIndexRes>> => {
    const { execute } = useAxios<AxiosResponse<ApiMenuIndexRes>>(
      ApiRouterConstants.API_BOOKING_MENUS,
      { method: "GET", params },
      http,
      { immediate: false }
    );
    return await execute();
  },
};

export default MenuServices;
