import { useCookies } from "@vueuse/integrations/useCookies";
import { get } from "@vueuse/core";
import type { IClient, IClinic } from "@/clinic/typings";
import AuthUtil from "../utils/auth.util";
import * as _ from "lodash";

const ACCESS_TOKEN = "access_token";
const CLINIC_ID = "clinic_id";
const CHANNEL_ID = "channel_id";
const COOKIE_PATH = "/";
const { get: getCookie, set: setCookie, remove: removeCookie } = useCookies();
const defaultState = (): Record<string, unknown> => {
  return {
    client: {} as IClient,
    access_token: "",
    selectedClinic: {} as IClinic,
  };
};

const AuthClient = {
  namespaced: true,
  state: defaultState,
  mutations: {
    setClient: (state: { client: IClient }, client: IClient): IClient => {
      return (state.client = client as IClient);
    },
    setAccessToken: (state: { accessToken: string }, token: string): string => {
      return (state.accessToken = token);
    },
    setClinic: (state: { selectedClinic: IClinic }, clinic: IClinic): IClinic => {
      return (state.selectedClinic = clinic);
    },
  },
  actions: {
    saveAccessTokenCookie({ getters }: any): void {
      const { accessToken } = getters;
      if (!accessToken) return;

      setCookie(ACCESS_TOKEN, accessToken, { path: COOKIE_PATH });
    },
    checkAccessTokenCookie({ getters }: any): boolean {
      const token = getCookie(ACCESS_TOKEN);
      const { accessToken: cToken } = getters;
      if (!token || !cToken || token != cToken) return false;
      return AuthUtil.checkAccessToken(token);
    },
    saveClinicCookie({ getters }: any): void {
      const { selectedClinic } = getters as { selectedClinic: IClinic };
      if (!selectedClinic) return;

      setCookie(CLINIC_ID, selectedClinic.id, { path: COOKIE_PATH });
      setCookie(CHANNEL_ID, selectedClinic.channel_id, { path: COOKIE_PATH });
    },
    getClinicIdCookie(): number {
      return get(getCookie(CLINIC_ID)) as number;
    },
    getChannelIdCookie(): number {
      return get(getCookie(CHANNEL_ID)) as number;
    },
    removeAccessTokenCookie(): void {
      const token = getCookie(ACCESS_TOKEN);
      if (!token) return;

      removeCookie(ACCESS_TOKEN, { path: COOKIE_PATH });
    },
  },
  getters: {
    client: (state: { client: IClient }): IClient => {
      return state.client;
    },
    accessToken: (state: { accessToken: string }): string => {
      return state.accessToken;
    },
    clinics: (state: { client: IClient }): IClinic[] => {
      return state.client?.clinics || [];
    },
    firstClinic: (state: { client: IClient }): IClinic => {
      return _.head(state.client?.clinics) as IClinic;
    },
    selectedClinic: (state: { selectedClinic: IClinic }): IClinic => {
      return state.selectedClinic;
    },
  },
};

export default AuthClient;
