import { useAxios } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";
import http from "@/common/utils/http-common-utils";
import { AnyFn, Fn } from "@vueuse/core";

const BookingNotificationsService = {
  fetch: async (params: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_BOOKING_NOTIFICATIONS}`,
      { method: "GET", params: params },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  count: async (params: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_BOOKING_NOTIFICATIONS}/count`,
      { method: "GET", params: params },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  update: async (id: string, data: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_BOOKING_NOTIFICATIONS}/${id}`,
      { method: "PUT", data: data },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
};

export default BookingNotificationsService;
