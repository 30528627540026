import { BookingCustomerStatus } from "@/clinic/typings/enums/booking";
import { MaybeRefOrGetter } from "@vueuse/core";
import type { Rule, Rules } from "async-validator";
type IFormRuleValidation = {
  startTime: string;
  endTime: string;
  customerStatus: string;
};
const emptyMsg = "*このフィールドは必須です。";
const newCustomerFieldValidates: Record<string, Rule | Rules> = {
  customerName: {
    type: "string",
    required: true,
    message: emptyMsg,
  },
};
const existedFieldValidates: Record<string, Rule | Rules> = {
  customerId: {
    type: "number",
    required: true,
    message: emptyMsg,
    validator: (_rule, customerId: number) => customerId > 0,
  },
};

const newFieldValidates = (customerStatus: string): Record<string, Rule | Rules> =>
  customerStatus == BookingCustomerStatus.new ? newCustomerFieldValidates : existedFieldValidates;

const rules = (fieldTime: IFormRuleValidation): MaybeRefOrGetter<Record<string, Rule | Rules>> => {
  const { startTime: start, endTime: end, customerStatus } = fieldTime;
  const hasValidateHour = (): boolean => end > start;
  return {
    startTime: [
      {
        type: "string",
        required: true,
        message: emptyMsg,
      },
      {
        validator: () => hasValidateHour(),
        message: "終了時間より大きくすることはできません。",
      },
    ],
    endTime: [
      {
        type: "string",
        required: true,
        message: emptyMsg,
      },
      {
        validator: () => hasValidateHour(),
        message: "開始時間より過去にすることはできません。",
      },
    ],
    medicalMenuId: {
      type: "number",
      required: true,
      message: emptyMsg,
      min: 1,
    },
    staffId: {
      type: "number",
      required: true,
      message: emptyMsg,
    },
    ...newFieldValidates(customerStatus),
  };
};

export default rules;
