export const REQUIRED_MESSAGE = "このフィールドは必須です。";
export const KATAKANA_MESSAGE = "カタカナを入力してください。";
export const CUSTOMER_ID_MESSAGE = "顧客IDは10文字以内で入力してください。";
export const EMAIL_MESSAGE = "無効なメールアドレスです。";
export const BIRTH_DAY_MESSAGE = "誕生日が本日より大きくすることはできません。";
export const REQUIRED_MEMO_MESSAGE = "メモを入力してください。";
export const MAX_CHARACTERS_MEMO_MESSAGE = "300文字以下にしてください。";
export const MAX_TAG_MANUAL_MESSAGE = "最大10個しか作成できません。";
export const START_END_TIME_MESSAGE = "終了時間より大きくすることはできません。";
export const END_START_TIME_MESSAGE = "開始時間より過去にすることはできません。";
export const END_START_MID_DAY_TIME_MESSAGE = "夜晩より大きくすることはできません。";
export const START_END_MID_DAY_TIME_MESSAGE = "朝晩より過去にすることはできません。";
