import {
  MenuOptionNotificationLineConstants,
  NotificationLineConstants,
} from "@/clinic/constants/notification.line.constants";

export default class LineNotificationUtils {
  public static contentToHtml(content: string): string {
    const processedContent = this.convertTextInBrackets(this.convertQuillHtml(content));
    return this.removeEmptyLinesAndChangeTabSpace(processedContent);
  }

  private static convertQuillHtml = (quillHtml: string): string => {
    const hiddenDiv = document.createElement("div");
    hiddenDiv.innerHTML = quillHtml;

    hiddenDiv.querySelectorAll("span[data-tag]").forEach(span => {
      const key = span.getAttribute("data-tag");
      span.outerHTML = `{{${key}}}`;
    });

    return Array.from(hiddenDiv.querySelectorAll("p"))
      .map(paragraph => paragraph.textContent)
      .join("\n");
  };

  private static convertTextInBrackets = (text: string): string => {
    const regex = /{{(.*?)}}/g;
    const regexOptions = Object.values(MenuOptionNotificationLineConstants).join("|");
    const regexMedical = new RegExp(`{{(${regexOptions})\\s*(\\d*)}}`, "g");

    return text.replace(regex, (match, constantName) => {
      const key = this.getKeyByValue(NotificationLineConstants, constantName.trim());
      if (key !== undefined) {
        return `{{${key}}}`;
      } else {
        return match.replace(regexMedical, (match, optionType, optionNumber) => {
          const menuKey = this.getKeyByValue(MenuOptionNotificationLineConstants, optionType);
          return `{{${menuKey}${optionNumber ?? ""}}}`;
        });
      }
    });
  };

  private static getKeyByValue<T>(object: { [key: string]: T }, value: T): string | undefined {
    return Object.keys(object).find(key => object[key] === value);
  }

  private static removeEmptyLinesAndChangeTabSpace = (content: string): string => {
    return content
      .split("\n")
      .filter(line => line.trim() !== "")
      .join("\n")
      .replace(/\t/g, "    ");
  };
}
