import { useAxios } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";
import http from "@/common/utils/http-common-utils";
import type { ApiMedicalMenuCategoryIndexReq, ApiMedicalMenuCategoryReq } from "@/clinic/typings";

const MedicalMenuCategoryServices = {
  fetchCategories: async (
    params: ApiMedicalMenuCategoryIndexReq,
    onSuccess: () => void,
    onError: () => void,
    onFinish: () => void
  ) => {
    const { execute } = useAxios(
      ApiRouterConstants.API_MEDICAL_MENU_CATEGORIES,
      { method: "GET", params: params },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          onError();
        },
        onFinish: () => {
          onFinish();
        },
      }
    );
    return await execute();
  },
  deleteCategory: async (params: ApiMedicalMenuCategoryReq, onSuccess: () => void, onError: () => void) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_MEDICAL_MENU_CATEGORIES}/${params.id}`,
      { method: "DELETE", params: { clinic_id: params.clinic_id } },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          onError();
        },
      }
    );
    return await execute();
  },
  saveCategory: async (formObject: any, onSuccess: () => void, onError: (errors: any) => void) => {
    const method = formObject.id ? "PATCH" : "POST";
    const url = formObject.id
      ? `${ApiRouterConstants.API_MEDICAL_MENU_CATEGORIES}/${formObject.id}`
      : ApiRouterConstants.API_MEDICAL_MENU_CATEGORIES;
    const { execute } = useAxios(url, { method: method }, http, {
      immediate: false,
      onSuccess: () => {
        onSuccess();
      },
      onError: (errors: any) => {
        onError(errors.response?.data);
      },
    });
    return await execute({ params: formObject });
  },
};

export default MedicalMenuCategoryServices;
