import { useCookies } from "@vueuse/integrations/useCookies";
import { AxiosInstance } from "axios";
import AuthUtil from "../utils/auth.util";

const { get: getCookie } = useCookies();

const setupAuth = (instance: AxiosInstance): any => {
  return instance.interceptors.request.use(
    (config: any) => {
      const token = getCookie("access_token");
      if (!config.headers["Content-Type"]) {
        config.headers["Content-Type"] = "application/json";
      }
      if (token && AuthUtil.checkAccessToken(token)) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (err: any) => {
      return Promise.reject(err);
    }
  );
};

export { setupAuth };
