import { createStore } from "vuex";
import Booking from "./booking";
import Customer from "./customer";
import LineAccountStore from "./line-account";
import AuthClient from "./auth-client";
import BookingSetting from "./booking-setting";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    authClient: AuthClient,
    booking: Booking,
    customer: Customer,
    line_account: LineAccountStore,
    bookingSetting: BookingSetting,
  },
});
