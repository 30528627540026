import http from "@/common/utils/http-common-utils";
import { useAxios, StrictUseAxiosReturn } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";
import { ILinesMenuTemplate } from "../typings";
import { AxiosResponse } from "axios";

const RichMenuTemplateServices = {
  getRichMenuTemplates: async (): Promise<
    StrictUseAxiosReturn<AxiosResponse<ILinesMenuTemplate[]>, AxiosResponse, ILinesMenuTemplate[]>
  > => {
    const { execute } = useAxios<AxiosResponse<ILinesMenuTemplate[]>, AxiosResponse, ILinesMenuTemplate[]>(
      ApiRouterConstants.API_RICH_MENU_TEMPLATES,
      {
        method: "GET",
      },
      http,
      { immediate: false }
    );
    return await execute();
  },
};

export default RichMenuTemplateServices;
