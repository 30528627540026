import http from "@/common/utils/http-common-utils";
import { useAxios, StrictUseAxiosReturn } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";
import { AxiosResponse } from "axios";
import { IRichMenu, IRichMenuDetail, ApiRichMenuBaseReq, ApiDetailRichMenuBaseReq } from "../typings";

const RichMenuServices = {
  getRichMenus: async (
    params: ApiRichMenuBaseReq
  ): Promise<StrictUseAxiosReturn<AxiosResponse<IRichMenu[]>, AxiosResponse, IRichMenu[]>> => {
    const { execute } = useAxios<AxiosResponse<IRichMenu[]>, AxiosResponse, IRichMenu[]>(
      ApiRouterConstants.API_RICH_MENUS,
      {
        method: "GET",
      },
      http,
      { immediate: false }
    );
    return await execute({ params });
  },
  getRichMenu: async (
    params: ApiDetailRichMenuBaseReq
  ): Promise<StrictUseAxiosReturn<AxiosResponse<IRichMenuDetail[]>, AxiosResponse, IRichMenuDetail[]>> => {
    const { id } = params;
    const { execute } = useAxios<AxiosResponse<IRichMenuDetail[]>, AxiosResponse, IRichMenuDetail[]>(
      `${ApiRouterConstants.API_RICH_MENUS}/${id}`,
      {
        method: "GET",
      },
      http,
      { immediate: false }
    );
    return await execute({ params });
  },
  deleteRichMenu: async (
    id: number,
    params: ApiRichMenuBaseReq,
    onSuccess: () => void,
    onError: (error: any) => void
  ): Promise<StrictUseAxiosReturn<AxiosResponse<IRichMenuDetail[]>, AxiosResponse, IRichMenuDetail[]>> => {
    const { execute } = useAxios<AxiosResponse<IRichMenuDetail[]>, AxiosResponse, IRichMenuDetail[]>(
      `${ApiRouterConstants.API_RICH_MENUS}/${id}`,
      {
        method: "DELETE",
      },
      http,
      {
        immediate: false,
        onSuccess: () => {
          if (onSuccess) onSuccess();
        },
        onError: error => {
          if (onError) onError(error);
        },
      }
    );
    return await execute({ params });
  },
  publishRichMenu: async (
    id: number,
    params: ApiRichMenuBaseReq,
    onSuccess: () => void,
    onError: (error: any) => void
  ): Promise<StrictUseAxiosReturn<AxiosResponse<IRichMenuDetail[]>, AxiosResponse, IRichMenuDetail[]>> => {
    const { execute } = useAxios<AxiosResponse<IRichMenuDetail[]>, AxiosResponse, IRichMenuDetail[]>(
      `${ApiRouterConstants.API_RICH_MENUS}/${id}/publish`,
      {
        method: "PATCH",
      },
      http,
      {
        immediate: false,
        onSuccess: () => {
          if (onSuccess) onSuccess();
        },
        onError: error => {
          if (onError) onError(error);
        },
      }
    );
    return await execute({ params });
  },
};

export default RichMenuServices;
