export const NotificationLineConstants = {
  CustomerName: "友だちの表示名",
  ClinicName: "アカウント名",
  ClinicAddress: "店舗住所",
  ClinicTel: "店舗電話番号",
  BookingId: "予約番号",
  TotalAmount: "合計金額",
  MenuName: "メニュー名",
  MenuTime: "メニュー時間",
  MenuPrice: "メニュー価格",
  BookingUrl: "予約URL",
  BookingDate: "予約日時",
  Separator: "区切り",
  EmptyLine: "空行",
  RepeatBookingTime: "定期予約の日程",
  RepeatEnd: "定期予約の最終日",
  NominationFee: "指名料",
};

export const MenuOptionNotificationLineConstants = {
  MenuOptionName: "オプションメニュー名",
  MenuOptionTime: "オプションメニュー時間",
  MenuOptionPrice: "オプションメニュー価格",
};

export const MessageTypeNotificationLineConstants = [
  { message_type_title: "予約確定／予約登録したときの通知", value: "created" },
  { message_type_title: "リクエスト予約を承認したときの通知", value: "approved" },
  { message_type_title: "リクエスト予約をキャンセルしたときの通知", value: "request_booking_canceled_by_clinic" },
  { message_type_title: "1日前リマインダー", value: "one_day_reminder" },
  { message_type_title: "お客様がリクエスト予約したときの通知", value: "request_booking_created" },
  { message_type_title: "お客様がマイページからキャンセルしたときの通知", value: "canceled_by_customer" },
  { message_type_title: "予約詳細からキャンセルしたときの通知", value: "canceled_by_clinic" },
  { message_type_title: "予約日時を変更したときの通知", value: "changed_booking_details_by_clinic" },
  { message_type_title: "お客様がマイページから日時変更したときの通知", value: "changed_booking_details_by_customer" },
  {
    message_type_title: "定期予約した時の通知",
    value: "batch_created_by_clinic",
  },
];
