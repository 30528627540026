import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "@/common/constants/api.router.constants";
import { useAxios } from "@vueuse/integrations/useAxios";

const MedicalMenuServices = {
  fetchMedicalMenu: async (clinicId: number, staffId: number, menuId: number) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_MENU}/${menuId}?clinic_id=${clinicId}&staff_id=${staffId}`,
      { method: "GET" },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
};

export default MedicalMenuServices;
