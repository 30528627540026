import { getHeightAndWidthFromDataUrl } from "@/clinic/utils/image-utils";
import { IFile } from "@/clinic/typings/constants/file";

export const avatarStaffValidate = async (files: IFile[]): Promise<Error | boolean> => {
  if (files) {
    for (const file of files) {
      const mimeTypes = ["image/jpeg", "image/png"];
      const fileSize = file.size;
      const { width, height } = (await getHeightAndWidthFromDataUrl(file?.objectURL)) as any;

      // Check if the file type is valid
      if (!mimeTypes.includes(file.type)) {
        return false;
      }

      // Check if the file dimensions are correct
      if (width >= 1024 || height >= 1024) {
        return new Error("高さ/幅 (ピクセル): 1024 未満");
      }

      // Check if the file size is valid
      if (fileSize > 10000000) {
        return new Error("最大サイズファイル:10MB");
      }
    }
  }
  return true;
};
