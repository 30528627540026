import moment from "moment";
import axios from "axios";
import ApiRouterConstants from "../constants/api.router.constants";
import { useStorage } from "@vueuse/core";

const defaultState = () => {
  return {
    bookingId: useStorage("bookingId", 0, sessionStorage),
    selectedStaffId: useStorage("selectedStaffId", undefined, sessionStorage),
    useDefaultStaff: useStorage("useDefaultStaff", false, sessionStorage),
    autoStaffDistribution: {
      data: useStorage("autoStaffDistribution::data", [], sessionStorage),
      selectedStaffId: useStorage("autoStaffDistribution::selectedStaffId", "", sessionStorage),
    },
    distributedStaffid: useStorage("distributedStaffid", undefined, sessionStorage),
    selectedMenuCategoryId: useStorage("selectedMenuCategoryId", 0, sessionStorage),
    selectedMenu: {
      id: useStorage("selectedMenu::id", 0, sessionStorage),
      price: useStorage("selectedMenu::price", 0, sessionStorage),
      time: useStorage("selectedMenu::time", 0, sessionStorage),
      name: useStorage("selectedMenu::name", "", sessionStorage),
      displayPrice: useStorage("selectedMenu::displayPrice", false, sessionStorage),
      bookingType: useStorage("selectedMenu::bookingType", "normal", sessionStorage),
    },
    selectedOptions: useStorage("selectedOptions", [], sessionStorage),
    selectedRequestDates: {
      first: useStorage("selectedRequestDates::first", "", sessionStorage),
      second: useStorage("selectedRequestDates::second", "", sessionStorage),
      third: useStorage("selectedRequestDates::third", "", sessionStorage),
    },
    selectedTime: useStorage("selectedTime", {}, sessionStorage),
    customerInfo: {
      email: useStorage("customerInfo::email", "", sessionStorage),
      firstName: useStorage("customerInfo::firstName", "", sessionStorage),
      lastName: useStorage("customerInfo::lastName", "", sessionStorage),
      tel: useStorage("customerInfo::tel", "", sessionStorage),
      zipcode: useStorage("customerInfo::zipcode", "", sessionStorage),
      address: useStorage("customerInfo::address", "", sessionStorage),
      addressNumber: useStorage("customerInfo::addressNumber", "", sessionStorage),
      birthday: useStorage("customerInfo::birthday", "", sessionStorage),
      gender: useStorage("customerInfo::gender", "male", sessionStorage),
      note: useStorage("customerInfo::note", "", sessionStorage),
      noteSymptomAreas: useStorage("customerInfo::noteSymptom", "", sessionStorage),
    },
    messageToken: useStorage("booking::messageToken", "", sessionStorage),
    messageType: useStorage("booking::messageType", "", sessionStorage),
    headerBookingDetails: useStorage("booking:headerBookingDetails", "", sessionStorage),
    notificationsBookingDetails: useStorage("booking:BookingNotifyBookingDetails", "", sessionStorage),
    notificationsBookingRequest: useStorage("booking:BookingNotifyBookingDetails", "", sessionStorage),
    submited: false,
    totalPrice: 0,
    edit: false,
    selectedStaff: useStorage("selectedStaff", {}, sessionStorage),
  };
};

const Booking = {
  namespaced: true,
  state: defaultState,
  mutations: {
    setEditFlag: (state: any, edit: boolean) => {
      state.edit = edit;
    },
    setUseDefaultStaff: (state: any, enable: boolean) => {
      state.useDefaultStaff = enable;
    },
    selectStaff: (state: any, staff: any) => {
      state.selectedStaffId = staff.id || staff;
      state.selectedStaff = staff;
    },
    selectMenuCategory: (state: any, id: number) => {
      state.selectedMenuCategoryId = id;
    },
    selectMenu: (state: any, menu: any) => {
      state.selectedMenu.id = menu.id;
      state.selectedMenu.price = menu.unit_price;
      state.selectedMenu.time = menu.time_unit;
      state.selectedMenu.name = menu.name;
      state.selectedMenu.displayPrice = menu.display_price;
      state.selectedMenu.bookingType = menu.booking_type;
    },
    selectOptions: (state: any, options: any[]) => {
      const menuOptions = options.map(option => {
        return {
          id: option.id,
          price: option.unit_price,
          time: option.time_unit,
          name: option.name,
          displayPrice: option.display_price,
        };
      });
      state.selectedOptions = menuOptions;
    },
    selectTime: (state: any, time: any) => {
      const date = moment(time);
      state.selectedTime = {
        date: date.format("YYYY-MM-DD"),
        time: date.format("HH:mm"),
      };
    },
    setHeaderBookingDetails: (state: any, details: any) => {
      state.headerBookingDetails = details;
    },
    setNotificationsBookingDetails: (state: any, details: any) => {
      state.notificationsBookingDetails = details;
    },
    setNotificationsBookingRequests: (state: any, request: any) => {
      state.notificationsBookingRequests = request;
    },
    setCustomerInfo: (state: any, info: any) => {
      state.customerInfo.email = info.email;
      state.customerInfo.firstName = info.firstName;
      state.customerInfo.lastName = info.lastName;
      state.customerInfo.tel = info.tel;
      state.customerInfo.zipcode = info.zipcode;
      state.customerInfo.address = info.address;
      state.customerInfo.addressNumber = info.addressNumber;
      state.customerInfo.birthday = info.birthday;
      state.customerInfo.gender = info.gender;
      state.customerInfo.note = info.note;
      state.customerInfo.noteSymptom = info.noteSymptom;
    },
    setMessage: (state: any, message: any) => {
      state.messageToken = message.token;
      state.messageType = message.type;
    },
    resetState(state: any) {
      const { messageToken, messageType } = state;
      sessionStorage.clear();
      Object.assign(state, defaultState());
      state.messageToken = messageToken;
      state.messageType = messageType;
    },
    resetSelectedTime(state: any) {
      state.selectedTime = {};
    },
    setBookingParams(state: any, booking: any) {
      state.bookingId = booking.id;
      state.customerInfo.note = booking.note;
      state.selectedStaffId = undefined;
      state.selectedMenu.id = booking.medical_menu_id;
      state.selectedMenu.time = booking.medical_menu.time_unit;
      state.selectedOptions = booking.medical_options.map((option: any) => {
        return { id: option.id, time: option.time_unit };
      });
    },
    setRequestDates(state: any, dates: any) {
      state.selectedRequestDates.first = dates.first;
      state.selectedRequestDates.second = dates.second;
      state.selectedRequestDates.third = dates.third;
    },
    setAutoStaffDistributionData: (state: any, data: number[]) => {
      state.autoStaffDistribution.data = data;
    },
    selectDistributedStaff: (state: any, staffId: any) => {
      state.autoStaffDistribution.selectedStaffId = staffId;
    },
  },
  actions: {
    submitBooking({ state, getters, rootState }: any, callbacks: any) {
      let method = "POST";
      let url = ApiRouterConstants.API_BOOKING;

      if (state.bookingId != 0) {
        method = "PATCH";
        url = `${url}/${state.bookingId}`;
      }

      const options = {
        method: method,
        url: url,
        params: {
          clinic_id: rootState.line_account.clinic_id,
          staff_id: rootState.bookingSetting.autoStaffDistribution ? undefined : state.selectedStaffId,
          distributed_staff_id: rootState.bookingSetting.autoStaffDistribution
            ? state.autoStaffDistribution.selectedStaffId
            : undefined,
          customer_id: rootState.line_account.customerId,
          medical_menu_category_id: state.selectedMenuCategoryId == 0 ? null : state.selectedMenuCategoryId,
          medical_menu_id: state.selectedMenu.id,
          line_account_id: rootState.line_account.id,
          customer_email: state.customerInfo.email,
          customer_name: `${state.customerInfo.firstName} ${state.customerInfo.lastName}`,
          customer_phone: state.customerInfo.tel,
          customer_birthday: state.customerInfo.birthday,
          customer_address: state.customerInfo.address,
          customer_address_number: state.customerInfo.addressNumber,
          customer_zipcode: state.customerInfo.zipcode,
          customer_gender: state.customerInfo.gender,
          message_token: state.messageToken,
          message_type: state.messageType,
          note: state.customerInfo.note,
          note_symptom: state.customerInfo.noteSymptom,
          booking_type: state.selectedMenu.bookingType,
          request_date_first:
            state.selectedRequestDates.first == null
              ? ""
              : moment(state.selectedRequestDates.first).format("YYYY-MM-DD HH:mm:ss"),
          request_date_second:
            state.selectedRequestDates.second == null
              ? ""
              : moment(state.selectedRequestDates.second).format("YYYY-MM-DD HH:mm:ss"),
          request_date_third:
            state.selectedRequestDates.third == null
              ? ""
              : moment(state.selectedRequestDates.third).format("YYYY-MM-DD HH:mm:ss"),
          medical_option_ids: state.selectedOptions.map((option: any) => option.id),
          start_time:
            state.selectedTime.date == null
              ? ""
              : moment(`${state.selectedTime.date} ${state.selectedTime.time}`).format("YYYY-MM-DD HH:mm:ss"),
          end_time:
            state.selectedTime.date == null
              ? ""
              : moment(`${state.selectedTime.date} ${state.selectedTime.time}`)
                  .add(getters.totalTime, "minutes")
                  .format("YYYY-MM-DD HH:mm:ss"),
        },
        headers: { Accept: "application/json" },
      };

      axios
        .request(options)
        .then(response => {
          state.totalPrice = response.data.final_amount;
          state.submited = true;
          callbacks.success();
        })
        .catch(response => {
          callbacks.error(response);
        });
    },
  },
  getters: {
    editFlag: (state: any) => {
      return state.edit;
    },
    selectedRequestDates: (state: any) => {
      return state.selectedRequestDates;
    },
    selectedStaffId: (state: any) => {
      return state.selectedStaffId;
    },
    selectUseDefaultStaff: (state: any) => {
      return state.useDefaultStaff;
    },
    selectedMenuCategoryId: (state: any) => {
      return state.selectedMenuCategoryId;
    },
    selectedMenu: (state: any) => {
      return state.selectedMenu;
    },
    selectedOptions: (state: any) => {
      return state.selectedOptions;
    },
    selectedTime: (state: any) => {
      return state.selectedTime;
    },
    headerBookingDetails: (state: any) => {
      return state.headerBookingDetails;
    },
    notificationsBookingDetails: (state: any) => {
      return state.notificationsBookingDetails;
    },
    notificationsBookingRequest: (state: any) => {
      return state.notificationsBookingRequest;
    },
    customerInfo: (state: any) => {
      return state.customerInfo;
    },
    submited: (state: any) => {
      return state.submited;
    },
    totalPrice(state: any) {
      return state.totalPrice;
    },
    totalOptionsTime(state: any) {
      let totalTime = 0;

      state.selectedOptions.forEach(function (option: any) {
        totalTime += option.time;
      });

      return totalTime;
    },
    totalTime(state: any) {
      let totalTime = state.selectedMenu.time;

      state.selectedOptions.forEach(function (option: any) {
        totalTime += option.time;
      });

      return totalTime;
    },
    autoStaffDistributionData: (state: any) => {
      return state.autoStaffDistribution.data;
    },
    distributedStaffid: (state: any) => {
      return state.autoStaffDistribution.selectedStaffId;
    },
    selectedStaff: (state: any) => {
      return state.selectedStaff;
    },
  },
};

export default Booking;
