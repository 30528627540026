import { MaybeRefOrGetter } from "@vueuse/core";
import type { Rule, Rules } from "async-validator";

const rules: MaybeRefOrGetter<Record<string, Rule | Rules>> = {
  name: [
    { required: true, message: "タグ名を入力してください" },
    { max: 10, message: "タグ名を10文字以内で入力してください" },
    {
      type: "string",
      transform: value => value.trim(),
    },
  ],
  color: [{ required: true, message: "タグの色を入力してください" }],
};

export default rules;
