import { AxiosResponse } from "axios";
import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "../../constants/api.router.constants";
import { ApiCusomterTagIndexParams, ITag } from "@/clinic/typings";
import { useAxios, EasyUseAxiosReturn } from "@vueuse/integrations/useAxios";
import { AnyFn, Fn } from "@vueuse/core";

const CustomerTagService = {
  getTags: async (
    tagParams: ApiCusomterTagIndexParams,
    onSuccess: Fn,
    onError: AnyFn
  ): Promise<EasyUseAxiosReturn<AxiosResponse<ITag[]>, AxiosResponse, ITag[]>> => {
    const { customer_id, ...params } = tagParams;
    const { execute } = useAxios<AxiosResponse<ITag[]>, AxiosResponse, ITag[]>(
      `${ApiRouterConstants.API_CUSTOMERS}/${customer_id}/tags`,
      {
        method: "GET",
        params,
      },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute();
  },
};

export default CustomerTagService;
