import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";
import { useCookies } from "@vueuse/integrations/useCookies";
import { StrictUseAxiosReturn, useAxios } from "@vueuse/integrations/useAxios";
import { AnyFn, Fn } from "@vueuse/shared";
import { ApiBaseReq, ApiLineAccountIndexReq, ApiLineAccountUnlinkedReq, ILineAccount } from "../typings";
import { AxiosResponse } from "axios";

const AccountServices = {
  changeSupportStatus: async (
    accountId: number,
    status: string,
    clinicId: number,
    onSuccess: Fn,
    onError: AnyFn
  ): Promise<void> => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_ACCOUNTS}/${accountId}/change_status`,
      {
        method: "PATCH",
      },
      http,
      {
        immediate: false,
        onSuccess,
        onError: (error: any) => {
          onError(error.response?.data?.errors);
        },
      }
    );
    await execute({ params: { support_status: status, clinic_id: clinicId } });
  },
  linkToCustomer: async (
    clinicId: number,
    customerId: number,
    accountId: number,
    successCallback: any,
    errorCallback: any
  ): Promise<void> => {
    const { get: getCookie } = useCookies();
    const token = getCookie("access_token");

    await http
      .post(
        `${ApiRouterConstants.API_ACCOUNTS}/link_to_customer`,
        {
          account_id: accountId,
          customer_id: customerId,
          clinic_id: clinicId,
        },
        {
          headers: { Accept: "application/json", Authorization: "Bearer " + token },
        }
      )
      .then(() => {
        successCallback();
      })
      .catch(() => {
        errorCallback();
      });
  },
  unlinked: async (
    params: ApiBaseReq,
    payload: ApiLineAccountUnlinkedReq,
    onSuccess: Fn,
    onError: AnyFn
  ): Promise<void> => {
    const { execute } = useAxios(
      ApiRouterConstants.API_UNLINKED_ACCOUNTS,
      { method: "PATCH", params, data: payload },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    await execute();
  },
  getAccounts: async (
    payload: ApiLineAccountIndexReq
  ): Promise<StrictUseAxiosReturn<AxiosResponse<ILineAccount[]>, AxiosResponse, ApiLineAccountIndexReq>> => {
    const { execute } = useAxios<AxiosResponse<ILineAccount[]>, AxiosResponse, ApiLineAccountIndexReq>(
      ApiRouterConstants.API_ACCOUNTS,
      { method: "GET", params: payload },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
};

export default AccountServices;
