import { get, useDateFormat } from "@vueuse/core";
import {
  DATE_FORMAT,
  DATE_ISO_FORMAT,
  TIME_FORMAT,
  TIME_EMPTY_HOUR_FORMAT,
  MILLISECONDS_IN_DAY,
  DATE_TIME_FORMAT,
} from "../typings/constants/booking";
import { IBookingPickedTime } from "../typings";
import { WeekDayEnum } from "../typings/enums/clinic";
import { DateRangeInput } from "@fullcalendar/core";
import DateUtils from "./date-utils";
import _ from "lodash";

export default class TimeUtils {
  public static dateToRangeTime(dateYMD: string): IBookingPickedTime {
    if (!dateYMD) return {} as IBookingPickedTime;
    return { start: new Date(`${dateYMD}T00:00:00+00:00`), end: new Date(`${dateYMD}T23:59:59+00:00`) };
  }

  public static toStartDateTime = (dateYMD: string): Date => new Date(`${dateYMD}T00:00:00+00:00`);

  public static toEndDateTime = (dateYMD: string): Date => new Date(`${dateYMD}T23:59:59+00:00`);

  public static toLocalTime(time: string): string {
    // To do will remove
    return time.replace(/(\+.*)|([zZ])/, "");
  }

  public static formatRangeDateTime = (
    startAt: Date | undefined,
    endAt: Date | undefined,
    hasTypeDateTime = true
  ): string => {
    if (!startAt || !endAt) return "";

    const [startDate, endDate] = [useDateFormat(startAt, DATE_FORMAT), useDateFormat(endAt, DATE_FORMAT)];
    const [startTime, endTime] = [useDateFormat(startAt, TIME_FORMAT), useDateFormat(endAt, TIME_FORMAT)];
    return `${get(startDate)} ${hasTypeDateTime ? get(startTime) : ""} ~ ${
      get(startDate) != get(endDate) ? get(endDate) : ""
    } ${hasTypeDateTime ? get(endTime) : ""}`;
  };

  public static formatDateYMD = (date: string): string =>
    get(useDateFormat(new Date(TimeUtils.toLocalTime(date)), DATE_FORMAT));

  public static formatDateTimeLocal = (date: string): string =>
    get(useDateFormat(new Date(TimeUtils.toLocalTime(date)), DATE_TIME_FORMAT));

  public static formatIsoDateYMD = (date: string): string =>
    get(useDateFormat(new Date(TimeUtils.toLocalTime(date)), DATE_ISO_FORMAT));

  public static toHourEmptyMinute = (date: string): string =>
    get(useDateFormat(new Date(TimeUtils.toLocalTime(date)), TIME_EMPTY_HOUR_FORMAT));

  public static toEndWeekDate = (start: Date): Date => {
    return TimeUtils.toEndDateTime(
      this.formatIsoDateYMD(new Date(start.getTime() + MILLISECONDS_IN_DAY * WeekDayEnum.saturday).toString())
    );
  };

  public static weekToday = (date = null): IBookingPickedTime => {
    const currentDate = date ? new Date(date) : new Date();
    const dayOfWeek = currentDate.getDay();

    const start = new Date();
    const end = new Date();

    if (dayOfWeek == 0) {
      start.setDate(currentDate.getDate() - 6);
    } else {
      start.setDate(currentDate.getDate() - (dayOfWeek - 1));
    }

    end.setDate(start.getDate() + 6);

    return { start, end };
  };

  public static validRange = (pickedDate: IBookingPickedTime, offDays: number[]): DateRangeInput => {
    const { start: startDate } = pickedDate;
    const filterFn = (date: Date): boolean => !offDays.includes(date.getDay());
    const ranges = DateUtils.toRangeDates(startDate, this.toEndWeekDate(startDate)).filter(filterFn);
    const { start, end } = { start: _.first(ranges), end: _.last(ranges) };
    return { start, end } as DateRangeInput;
  };
}
