import { useAxios } from "@vueuse/integrations/useAxios";
import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";

const WorkingTimeServices = {
  fetch: async (staffId: number, clinicId: number, date: string) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_STAFFS}/${staffId}/working_time?clinic_id=${clinicId}`,
      { method: "GET", params: { date: date } },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
};

export default WorkingTimeServices;
