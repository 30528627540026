import { FormatMoney } from "format-money-js";
const fm = new FormatMoney();
export default class NumberUtils {
  public static formatJPCurrency(value: number, symbol = "¥"): string {
    return fm.from(value, { symbol })?.toString() || "...";
  }

  public static toDate(value: number): Date {
    const UNIX_TIME = 1000;
    return new Date(value * UNIX_TIME);
  }
}
