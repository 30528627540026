import { ILineAccount } from "@/customer/typings";
import { useCookies } from "@vueuse/integrations/useCookies";
import { get } from "@vueuse/core";
import { useStorage } from "@vueuse/core";

const COOKIE_PATH = "/customer";
const CLINIC_ID = "clinic_id";
const LINE_USER_ID = "line_user_id";
const { get: getCookie, set: setCookie } = useCookies();
const defaultState = () => {
  return {
    id: useStorage("lineAccount::lineAccountId", 0, sessionStorage),
    name: "",
    line_user_id: "",
    customer_id: "",
    clinic_id: useStorage("lineAccount::clinicId", 0, sessionStorage),
    avatar_url: "",
  };
};

const LineAccountStore = {
  namespaced: true,
  state: defaultState,
  mutations: {
    setAccount: (state: any, account: ILineAccount): boolean => {
      Object.entries(account).forEach(([field, value]) => (value ? (state[field] = value) : undefined));
      return true;
    },
    setClinicId: (state: any, clinicId: number) => {
      state.clinic_id = clinicId;
    },
  },
  actions: {
    saveClinicIdCookie({ getters }: any): void {
      const { clinicId } = getters;
      if (!clinicId) return;

      setCookie(CLINIC_ID, clinicId, { path: COOKIE_PATH });
    },
    saveLineUserIdCookie({ getters }: any): void {
      const { lineUserId } = getters;
      if (!lineUserId) return;

      setCookie(LINE_USER_ID, lineUserId, { path: COOKIE_PATH });
    },
    getClinicIdCookie(): number {
      return get(getCookie(CLINIC_ID)) as number;
    },
    getLineUserIdCookie(): string {
      return get(getCookie(LINE_USER_ID)) as string;
    },
  },
  getters: {
    id: (state: ILineAccount): number => {
      return state.id;
    },
    name: (state: ILineAccount): string => {
      return state.name;
    },
    lineUserId: (state: ILineAccount): string => {
      return state.line_user_id;
    },
    customerId: (state: ILineAccount): string => {
      return state.customer_id;
    },
    clinicId: (state: ILineAccount): number => {
      return state.clinic_id;
    },
    avatarUrl: (state: ILineAccount): string => {
      return state.avatar_url;
    },
    account: (state: ILineAccount): ILineAccount => state,
  },
};

export default LineAccountStore;
