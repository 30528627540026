import http from "../../common/utils/http-common-utils";
import ApiRouterConstants from "../constants/api.router.constants";
import { ApiContactRequest, ApiContactResponse } from "../typings";
import { AxiosResponse } from "axios";
import { useAxios, EasyUseAxiosReturn } from "@vueuse/integrations/useAxios";

const ContactService = {
  saveContact: async (
    payload: ApiContactRequest,
    clinicId: number,
    onSuccess: () => void,
    onError: (error: any) => void
  ): Promise<EasyUseAxiosReturn<AxiosResponse<ApiContactResponse>, AxiosResponse, ApiContactResponse>> => {
    const { execute } = useAxios<AxiosResponse<ApiContactResponse>>(
      ApiRouterConstants.API_CONTACT,
      {
        method: "POST",
      },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: (error: any) => {
          onError(error.response?.data?.errors);
        },
      }
    );
    return await execute({ data: Object.assign({}, payload, { clinic_id: clinicId }) });
  },
};

export default ContactService;
