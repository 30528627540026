import { AxiosResponse } from "axios";
import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "../../constants/api.router.constants";
import { ApiClinicSettingStaffShiftParams } from "../../typings";
import { useAxios, EasyUseAxiosReturn } from "@vueuse/integrations/useAxios";
import { AnyFn } from "@vueuse/shared";
import { ApiStaffApplyTemplateReq } from "@/clinic/typings/apis/clinic-settings";

const ClinicStaffShiftsService = {
  applyPrevMonthShift: async (
    data: ApiClinicSettingStaffShiftParams,
    onSuccess: AnyFn,
    onError: AnyFn
  ): Promise<EasyUseAxiosReturn<AxiosResponse<any>, AxiosResponse, any>> => {
    const { execute } = useAxios<AxiosResponse<any>>(
      ApiRouterConstants.API_STAFF_SHIFT_APPLY_PREV_MONTH,
      { method: "POST", data },
      http,
      { immediate: false, onSuccess, onError }
    );
    return await execute();
  },
  show: async (
    params: ApiClinicSettingStaffShiftParams
  ): Promise<EasyUseAxiosReturn<AxiosResponse<{ [key: string]: any }>, AxiosResponse, { [key: string]: any }>> => {
    const { execute } = useAxios<AxiosResponse<{ [key: string]: any }>>(
      ApiRouterConstants.API_STAFF_SHIFT,
      { method: "GET", params },
      http,
      { immediate: false }
    );
    return await execute();
  },
  applyTemplate: async (
    data: ApiStaffApplyTemplateReq,
    onSuccess: AnyFn,
    onError: AnyFn
  ): Promise<EasyUseAxiosReturn<AxiosResponse<any>, AxiosResponse, any>> => {
    const { execute } = useAxios<AxiosResponse<any>>(
      ApiRouterConstants.API_STAFF_APPLY_TEMPLATE,
      { method: "POST", data },
      http,
      { immediate: false, onSuccess, onError }
    );
    return await execute();
  },
};

export default ClinicStaffShiftsService;
