import { MaybeRefOrGetter } from "@vueuse/core";
import type { Rule, Rules } from "async-validator";

const rules: MaybeRefOrGetter<Record<string, Rule | Rules>> = {
  messageTextContent: [
    { required: true, message: "テキストメッセージを入力してください" },
    { max: 500, message: "テキストメッセージは500文字以内で入力してください" },
    {
      type: "string",
      transform: value => value.trim(),
    },
  ],
  messageLinkUrl: [
    {
      validator: (rule, value, callback, source) => {
        if (![2, 4].includes(+source.messageType)) {
          callback();
        } else if (source.uriType != "uri") {
          callback();
        } else if (!value) {
          callback(new Error("メッセージリンクURLを入力してください"));
        } else if (!/^https?:\/\/\S+$/.test(value)) {
          callback(new Error("正しいURLを入力してください"));
        } else {
          callback();
        }
      },
    },
  ],
  messageImage: [
    {
      validator: (rule, value, callback, source) => {
        if (![3, 4].includes(+source.messageType)) {
          callback();
        } else if (!value) {
          callback(new Error("画像を選択してください"));
        } else if (value.size > 10 * 1024 * 1024) {
          callback(new Error("画像のファイルサイズは10MB以下である必要があります"));
        } else {
          callback();
        }
      },
    },
  ],
  imageActionPostBack: [
    {
      validator: (rule, value, callback, source) => {
        if (![3, 4].includes(+source.messageType) || source.messageImageActionType != "postback") {
          callback();
        } else if (value.length <= 0) {
          callback(new Error("テキストメッセージを入力してください"));
        } else {
          callback();
        }
      },
    },
    {
      validator: (rule, value, callback, source) => {
        if (![3, 4].includes(+source.messageType) || source.messageImageActionType != "postback") {
          callback();
        } else if (value.length > 100) {
          callback(new Error("テキストメッセージは100文字以内で入力してください"));
        } else {
          callback();
        }
      },
    },
  ],
  imageActionLink: [
    {
      validator: (rule, value, callback, source) => {
        if (![3, 4].includes(+source.messageType) || !["uri", "coupon"].includes(source.messageImageActionType)) {
          callback();
        } else if (!value) {
          callback(new Error("正しいURLを入力してください"));
        } else if (!/^https?:\/\/\S+$/.test(value)) {
          callback(new Error("正しいURLを入力してください"));
        } else {
          callback();
        }
      },
    },
  ],
};

export default rules;
