import { useAxios } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";
import http from "@/common/utils/http-common-utils";
import { AnyFn, Fn } from "@vueuse/core";

const DistributionGroupService = {
  createBasicInformation: async (params: any, data: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_DISTRIBUTION_GROUPS}/basic_settings`,
      { method: "POST", params: params, data: data },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  updateBasicInformation: async (distributionGroupId: any, params: any, data: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_DISTRIBUTION_GROUPS}/${distributionGroupId}/basic_settings`,
      { method: "PATCH", params: params, data: data },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  updateDeliverySettings: async (distributionGroupId: any, params: any, data: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_DISTRIBUTION_GROUPS}/${distributionGroupId}/delivery_settings`,
      { method: "PATCH", params: params, data: data },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  getAllTags: async (params: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_DISTRIBUTION_GROUPS}/target_settings/get_tags`,
      { method: "GET", params: params },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  destroy: async (distributionGroupId: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_DISTRIBUTION_GROUPS}/${distributionGroupId}`,
      { method: "DELETE" },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  getDistributionGroups: async (params: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_DISTRIBUTION_GROUPS}`,
      { method: "GET", params: params },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  getDistributionGroup: async (distributionGroupId: any, params: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_DISTRIBUTION_GROUPS}/${distributionGroupId}}`,
      { method: "GET", params: params },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  getFilterResult: async (params: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_DISTRIBUTION_GROUPS}/target_settings/filter`,
      { method: "GET", params: params },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  saveTargetCustomers: async (distributionGroupId: any, params: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_DISTRIBUTION_GROUPS}/${distributionGroupId}/customers`,
      { method: "PATCH", params: params },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  updateTargetSettings: async (distributionGroupId: any, params: any, data: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_DISTRIBUTION_GROUPS}/${distributionGroupId}/target_settings`,
      { method: "PATCH", params: params, data: data },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  getTargetCustomers: async (distributionGroupId: any, params: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_DISTRIBUTION_GROUPS}/${distributionGroupId}/customers`,
      { method: "GET", params: params },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  deleteTargetCustomers: async (distributionGroupId: any, params: any, onSuccess: Fn, onError: AnyFn) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_DISTRIBUTION_GROUPS}/${distributionGroupId}/customers`,
      { method: "DELETE", params: params },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute();
  },
  destroyDistributionGroups: async (params: any, onSuccess: Fn, onError: AnyFn) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_DISTRIBUTION_GROUPS}/batch_destroy`,
      { method: "DELETE", params: { ids: params } },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute();
  },
  changeStatus: async (distributionGroupId: any, params: any, data: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_DISTRIBUTION_GROUPS}/${distributionGroupId}/change_status`,
      { method: "PATCH", params: params, data: data },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  broadcast: async (distributionGroupId: any, params: any) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_DISTRIBUTION_GROUPS}/${distributionGroupId}/broadcast`,
      { method: "GET", params: params },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
};

export default DistributionGroupService;
