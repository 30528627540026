import type { ApiLineNotificationMessageIndexReq, ApiLineNotificationMessageReq } from "@/clinic/typings";
import { useAxios } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";
import http from "@/common/utils/http-common-utils";

const LineNotificationMessageServices = {
  fetchTemplates: async (
    params: ApiLineNotificationMessageIndexReq,
    onSuccess: () => void,
    onError: () => void,
    onFinish: () => void
  ) => {
    const { execute } = useAxios(
      ApiRouterConstants.API_LINE_NOTIFICATION_MESSAGES,
      { method: "GET", params: params },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          onError();
        },
        onFinish: () => {
          onFinish();
        },
      }
    );
    return await execute();
  },

  deleteTemplate: async (params: ApiLineNotificationMessageReq, onSuccess: () => void, onError: () => void) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_LINE_NOTIFICATION_MESSAGES}/${params.id}`,
      { method: "DELETE", params: { clinic_id: params.clinic_id } },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          onError();
        },
      }
    );
    return await execute();
  },

  saveTemplate: async (formObject: any, onSuccess: () => void, onError: () => void) => {
    const method = formObject.id == 0 ? "POST" : "PUT";
    const url =
      formObject.id == 0
        ? ApiRouterConstants.API_LINE_NOTIFICATION_MESSAGES
        : `${ApiRouterConstants.API_LINE_NOTIFICATION_MESSAGES}/${formObject.id}`;
    const { execute } = useAxios(url, { method: method }, http, {
      immediate: false,
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        onError();
      },
    });
    return await execute({ params: formObject });
  },

  fetchTemplate: async (params: ApiLineNotificationMessageReq, onSuccess: () => void, onError: () => void) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_LINE_NOTIFICATION_MESSAGES}/${params.id}`,
      { method: "GET", params: { clinic_id: params.clinic_id } },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          onError();
        },
      }
    );
    return await execute();
  },
};
export default LineNotificationMessageServices;
