import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "@/common/constants/api.router.constants";
import { useAxios } from "@vueuse/integrations/useAxios";

const MedicalOptionServices = {
  fetchMedicalOption: async (clinicId: number, medicalMenuId: number, medicalOptionId: number) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_MENU_OPTION}/${medicalOptionId}?clinic_id=${clinicId}&medical_menu_id=${medicalMenuId}`,
      { method: "GET" },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
};

export default MedicalOptionServices;
