import { ToastServiceMethods } from "primevue/toastservice";

export const notityErrorMsg = (toast: ToastServiceMethods, errorMsg: string) => {
  toast.add({
    severity: "error",
    detail: errorMsg,
    life: 3000,
  });
};

export const notifySuccessMsg = (toast: ToastServiceMethods, successMsg: string) => {
  toast.add({
    severity: "success",
    detail: successMsg,
    life: 2000,
  });
};
