export const TIME_FORMAT = "HH:mm";
export const DATE_ISO_FORMAT = "YYYY-MM-DD";
export const DATE_IN_WEEK_FORMAT = "ddd曜日";
export const LINE_ACCOUNT_DATE_IN_MONTH_FORMAT = "M/D";
export const DATE_IN_MONTH_FORMAT = "M/D(ddd曜)";
export const DATE_IN_YEAR_FORMAT = "YY/M/D(ddd曜)";
export const LINE_ACCOUNT_DATE_IN_YEAR_FORMAT = "M/D";
export const DATE_IN_PREVIOUS_YEAR_FORMAT = "YYYY/M/D(ddd曜)";
export const LINE_ACCOUNT_DATE_IN_PREVIOUS_YEAR_FORMAT = "YYYY/M/D";
export const CURRENT_DATE_JP = "今日";
export const PREVIOUS_DATE_JP = "昨日";
export const MESSAGE_FIRST_PAGINATOR_ROWS = 20;
export const MESSAGE_PAGINATOR_ROWS = 10;
export const CUSTOMER_FIRST_PAGINATOR_ROWS = 20;
