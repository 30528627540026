<template>
  <div class="nodata text-center">
    <p>まだトークデータがありません。</p>
  </div>
</template>

<style lang="scss" scoped>
.nodata {
  p {
    margin-top: 104px;
    font-size: 22px;
    line-height: 32.86px;
  }
}
</style>
