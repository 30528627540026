import { ApiClientDetailResponse, IClientDetail } from "../typings";
import axios, { AxiosResponse } from "axios";
import { useAxios, EasyUseAxiosReturn } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "../constants/api.router.constants";
import http from "../../common/utils/http-common-utils";
import { useCookies } from "@vueuse/integrations/useCookies";

const ClientDetailService = {
  getClientDetail: async (
    onError: (error: any) => void
  ): Promise<EasyUseAxiosReturn<AxiosResponse<ApiClientDetailResponse>, AxiosResponse, ApiClientDetailResponse>> => {
    const { execute } = useAxios<AxiosResponse<ApiClientDetailResponse>, AxiosResponse, ApiClientDetailResponse>(
      ApiRouterConstants.API_CLIENT_DETAILS,
      {
        method: "GET",
      },
      http,
      {
        immediate: false,
        onError: (error: any) => {
          onError(error.response?.data?.errors);
        },
      }
    );
    return await execute();
  },
  updateClientDetail: async (formData: any, onSuccess: () => void, onError: (error: any) => void) => {
    const { execute } = useAxios<AxiosResponse<IClientDetail, ApiClientDetailResponse>>(
      ApiRouterConstants.API_CLIENT_DETAILS,
      {
        method: "PATCH",
        data: formData,
      },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: (error: any) => {
          onError(error.response?.data?.error);
        },
      }
    );
    return await execute();
  },
};

export default ClientDetailService;
