import axios, { AxiosInstance } from "axios";
import RouterConstants from "../../common/constants/app.constants";
import { setupAuth } from "../interceptors/auth.interceptor";

const http: AxiosInstance = axios.create({
  baseURL: RouterConstants.API_URL,
});

setupAuth(http);

export default http;
