import { AxiosResponse } from "axios";
import http from "../../common/utils/http-common-utils";
import ApiRouterConstants from "../constants/api.router.constants";
import {
  ApiCampaignIndexReq,
  ApiCampaignMessageRes,
  ApiCampaignIndexRes,
  ApiCampaignNewReq,
  ApiCampaignShowReq,
  ICampaign,
  ApiCampaignSegmentsRes,
  ApiCampaignSegmentCategoriesRes,
  ApiCampaignSegmentCategoriesReq,
  ApiCampaignBaseReq,
} from "../typings";
import { useAxios, EasyUseAxiosReturn, StrictUseAxiosReturn } from "@vueuse/integrations/useAxios";
import { ApiCampaignChartRes } from "@/clinic/typings/apis/campaign";

const CampaignServices = {
  getCampaigns: async (
    params: ApiCampaignIndexReq,
    onSuccess: () => void,
    onError: (error: any) => void,
    onFinish: () => void
  ): Promise<EasyUseAxiosReturn<AxiosResponse<ApiCampaignIndexRes>, AxiosResponse, ApiCampaignIndexRes>> => {
    const { channel_id, ...dataParams } = params;
    type ApiCampaignParamsReq = Omit<ApiCampaignIndexReq, "channel_id">;
    const { execute } = useAxios<AxiosResponse<ApiCampaignIndexRes>, AxiosResponse, ApiCampaignIndexRes>(
      `${ApiRouterConstants.API_CAMPAIGNS}/${channel_id}`,
      {
        method: "GET",
        params: dataParams as ApiCampaignParamsReq,
      },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: (error: any) => {
          onError(error.response?.data?.errors);
        },
        onFinish: () => {
          onFinish();
        },
      }
    );
    return await execute();
  },
  saveCampaign: async (
    params: ApiCampaignShowReq,
    payload: ApiCampaignNewReq,
    onSuccess: () => void,
    onError: (error: any) => void
  ): Promise<void> => {
    const { id } = params;
    let url = ApiRouterConstants.API_CAMPAIGNS;
    let method = "POST";
    if (id != 0 && id) {
      url = `${url}/${id}`;
      method = "PATCH";
    }
    const { execute } = useAxios<AxiosResponse<ICampaign, ApiCampaignNewReq>>(
      url,
      { method, params, data: payload as ApiCampaignNewReq },
      http,
      {
        immediate: false,
        onSuccess: () => {
          if (onSuccess) onSuccess();
        },
        onError: error => {
          if (onError) onError(error);
        },
      }
    );
    await execute();
  },
  cancelCampaign: async (
    params: ApiCampaignShowReq,
    onSuccess: () => void,
    onError: (error: any) => void
  ): Promise<void> => {
    const { id, channel_id } = params;
    const url = `${ApiRouterConstants.API_CAMPAIGNS}/${id}/cancel?channel_id=${channel_id}`;
    const { execute } = useAxios<AxiosResponse>(url, { method: "PATCH" }, http, {
      immediate: false,
      onSuccess: () => {
        if (onSuccess) onSuccess();
      },
      onError: error => {
        if (onError) onError(error);
      },
    });
    await execute();
  },
  deliverCampaign: async (
    params: ApiCampaignShowReq,
    onSuccess: (response: any) => void,
    onError: (error: any) => void
  ): Promise<void> => {
    const { id, channel_id } = params;
    const url = `${ApiRouterConstants.API_CAMPAIGNS}/${id}/deliver?channel_id=${channel_id}`;
    const { execute } = useAxios<AxiosResponse>(url, { method: "PATCH" }, http, {
      immediate: false,
      onSuccess: (response: any) => {
        onSuccess(response);
      },
      onError: (error: any) => {
        onError(error.response?.data?.errors);
      },
    });
    await execute();
  },
  deleteCampaign: async (
    params: ApiCampaignShowReq,
    onSuccess: (response: any) => void,
    onError: (error: any) => void
  ): Promise<void> => {
    const { id, channel_id } = params;
    const url = `${ApiRouterConstants.API_CAMPAIGNS}/${id}?channel_id=${channel_id}`;
    const { execute } = useAxios<AxiosResponse>(url, { method: "DELETE" }, http, {
      immediate: false,
      onSuccess: (response: any) => {
        onSuccess(response);
      },
      onError: (error: any) => {
        onError(error.response?.data?.errors);
      },
    });
    await execute();
  },
  getCampaignMessage: async (
    params: ApiCampaignShowReq,
    onSuccess: () => void,
    onError: (error: any) => void,
    onFinish: () => void
  ): Promise<EasyUseAxiosReturn<AxiosResponse<ApiCampaignMessageRes>, AxiosResponse, ApiCampaignMessageRes>> => {
    const { id, ...dataParams } = params;
    type ApiCampaignMessageParamsReq = Omit<ApiCampaignIndexReq, "id">;
    const { execute } = useAxios<AxiosResponse<ApiCampaignMessageRes>, AxiosResponse, ApiCampaignMessageRes>(
      `${ApiRouterConstants.API_CAMPAIGNS}/${id}/messages`,
      {
        method: "GET",
        params: dataParams as ApiCampaignMessageParamsReq,
      },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: (error: any) => {
          onError(error.response?.data?.errors);
        },
        onFinish: () => {
          onFinish();
        },
      }
    );
    return await execute();
  },
  getCampaignSegments: async (): Promise<
    StrictUseAxiosReturn<AxiosResponse<ApiCampaignSegmentsRes>, AxiosResponse, ApiCampaignSegmentsRes>
  > => {
    const { execute } = useAxios<AxiosResponse<ApiCampaignSegmentsRes>>(
      ApiRouterConstants.API_CAMPAIGN_SEGMENTS,
      { method: "GET" },
      http,
      { immediate: false }
    );
    return await execute();
  },
  getCampaignSegmentCategories: async (
    params: ApiCampaignSegmentCategoriesReq
  ): Promise<
    StrictUseAxiosReturn<AxiosResponse<ApiCampaignSegmentCategoriesRes>, AxiosResponse, ApiCampaignSegmentCategoriesRes>
  > => {
    const { execute } = useAxios<AxiosResponse<ApiCampaignSegmentCategoriesRes>, AxiosResponse>(
      ApiRouterConstants.API_CAMPAIGN_SEGMENTS_CATEGORIES,
      { method: "GET", params },
      http,
      { immediate: false }
    );
    return await execute();
  },
  getCampaignChart: async (
    params: ApiCampaignBaseReq
  ): Promise<EasyUseAxiosReturn<AxiosResponse<ApiCampaignChartRes>, AxiosResponse, ApiCampaignChartRes>> => {
    const { channel_id, ...dataParams } = params;
    const { execute } = useAxios<AxiosResponse<ApiCampaignChartRes>, AxiosResponse>(
      `${ApiRouterConstants.API_CAMPAIGNS}/${channel_id}/analysis`,
      { method: "GET", params: dataParams as ApiCampaignBaseReq },
      http,
      { immediate: false }
    );
    return await execute();
  },
};

export default CampaignServices;
