import { AxiosResponse } from "axios";
import { AnyFn } from "@vueuse/core";
import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";
import { ApiStaffIndexRes, IStaff, ApiUpdateStaffPrioritiesPayload, ApiBaseReq } from "../../typings";
import { StrictUseAxiosReturn, useAxios } from "@vueuse/integrations/useAxios";

const StaffPrioritiesServices = {
  getList: async (
    params: ApiBaseReq
  ): Promise<StrictUseAxiosReturn<AxiosResponse<ApiStaffIndexRes>, AxiosResponse, ApiStaffIndexRes>> => {
    const { execute } = useAxios<AxiosResponse<ApiStaffIndexRes>>(
      ApiRouterConstants.API_STAFF_PRIORITIES,
      { method: "GET", params },
      http,
      { immediate: false }
    );
    return await execute();
  },

  updateList: async (
    payload: ApiUpdateStaffPrioritiesPayload,
    onSuccess: AnyFn,
    onError: AnyFn
  ): Promise<StrictUseAxiosReturn<AxiosResponse<IStaff[]>, AxiosResponse, IStaff[]>> => {
    const { execute } = useAxios<AxiosResponse<IStaff[]>>(
      ApiRouterConstants.API_STAFF_PRIORITIES,
      { method: "PUT", data: payload },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute();
  },
};

export default StaffPrioritiesServices;
