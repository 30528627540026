import { AxiosResponse } from "axios";
import { ApiBaseReqParams, IMedicalOption, ApiMedicalOptionIndexRes, ApiMedicalOptionTrashReq } from "../typings";
import { useAxios, EasyUseAxiosReturn } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";
import http from "@/common/utils/http-common-utils";
import RouterUtils from "@/common/utils/router.util";
import RouterConstants from "@/clinic/constants/router.constants";

const MedicalOptionServices = {
  saveOption: async (formObject: any, onSuccess: () => void, onError: (error: any) => void) => {
    const method = formObject.id == 0 ? "POST" : "PUT";
    const url =
      formObject.id == 0 ? ApiRouterConstants.API_OPTIONS : `${ApiRouterConstants.API_OPTIONS}/${formObject.id}`;
    const params: IMedicalOption = {
      ...formObject,
      clinic_id: formObject.clinicId,
      time_unit: formObject.timeUnit,
      unit_price: formObject.unitPrice,
      display_price: formObject.displayPrice,
      position_number: formObject.positionNumber,
    };

    const { execute } = useAxios(
      url,
      {
        method: method,
      },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: (error: any) => {
          onError(error.response?.data?.errors);
        },
      }
    );
    return await execute({ params: params });
  },
  getOptions: async (
    params: ApiBaseReqParams,
    onSuccess: () => void,
    onError: (error: any) => void
  ): Promise<EasyUseAxiosReturn<AxiosResponse<ApiMedicalOptionIndexRes>, AxiosResponse, ApiMedicalOptionIndexRes>> => {
    const { execute } = useAxios<AxiosResponse<ApiMedicalOptionIndexRes>, AxiosResponse, ApiMedicalOptionIndexRes>(
      ApiRouterConstants.API_OPTIONS,
      {
        method: "GET",
        params,
      },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: (error: any) => {
          onError(error.response?.data?.errors);
        },
      }
    );
    return await execute();
  },
  getOption: async (
    id: number,
    clinicId: number
  ): Promise<EasyUseAxiosReturn<AxiosResponse<IMedicalOption>, AxiosResponse, IMedicalOption>> => {
    const { execute } = useAxios<AxiosResponse<IMedicalOption>, AxiosResponse, IMedicalOption>(
      `${ApiRouterConstants.API_OPTIONS}/${id}?clinic_id=${clinicId}`,
      {
        method: "GET",
      },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  trashMedicalOption: async (
    id: number,
    params: ApiMedicalOptionTrashReq,
    onSuccess: () => void,
    onError: (error: any) => void
  ): Promise<void> => {
    const url = `${ApiRouterConstants.API_OPTIONS}/${id}`;
    const { execute } = useAxios<ApiMedicalOptionTrashReq>(url, { method: "DELETE", params }, http, {
      immediate: false,
      onSuccess: async () => {
        if (onSuccess) onSuccess();
        await new Promise(resolve => setTimeout(resolve, 1000));
        RouterUtils.goTo(RouterConstants.MEDICAL_OPTIONS_ROUTER_NAME);
      },
      onError: error => {
        if (onError) onError(error);
      },
    });
    await execute();
  },
};

export default MedicalOptionServices;
