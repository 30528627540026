import { useAxios } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "@/customer/constants/api.router.constants";
import http from "@/common/utils/http-common-utils";
import type { ApiMedicalMenuCategoryIndexReq } from "@/clinic/typings";

const MedicalMenuCategoryServices = {
  fetchCategories: async (
    params: ApiMedicalMenuCategoryIndexReq,
    onSuccess: () => void,
    onError: () => void,
    onFinish: () => void
  ) => {
    const { execute } = useAxios(
      ApiRouterConstants.API_MEDICAL_MENU_CATEGORIES,
      { method: "GET", params: params },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          onError();
        },
        onFinish: () => {
          onFinish();
        },
      }
    );
    return await execute();
  },
};

export default MedicalMenuCategoryServices;
