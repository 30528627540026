<template>
  <div class="campaign-page main-content-wrapper">
    <h1 class="main-content-page-title">キャンペーン</h1>
    <div class="text-center mt-8">
      <p class="text-lg font-medium">現在、作成されているキャンペーンがありません。</p>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped></style>
