import { MaybeRefOrGetter } from "@vueuse/core";
import type { Rule, Rules } from "async-validator";
import { REQUIRED_MESSAGE } from "@/common/constants/validate-message.constants";

const rules: MaybeRefOrGetter<Record<string, Rule | Rules>> = {
  title: {
    type: "string",
    required: true,
    transform: value => value.trim(),
    message: REQUIRED_MESSAGE,
  },
  content: {
    type: "string",
    required: true,
    transform: value => value.replace(/(<([^>]+)>)/gi, "").trim(),
    message: REQUIRED_MESSAGE,
  },
};

export default rules;
