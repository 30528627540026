import type { IMessageItem } from "../entities/message-item";
import DateUtils from "../../utils/date-utils";
import { CategoryEnum, StatusEnum } from "../enums/message-item";

class MessageItem {
  id: number;
  status: StatusEnum;
  title: string;
  send_date: string;
  created_at: string;
  targets?: Record<string, any>;
  number_of_openings: number;
  category: CategoryEnum;
  message_open_object?: string;
  amount_opened_messages?: string;

  constructor(message: IMessageItem) {
    this.id = message.id;
    this.title = message.title;
    this.send_date = message.send_date;
    this.created_at = message.created_at;
    this.category = (<CategoryEnum>(<unknown>CategoryEnum))[message.category as unknown as number] as CategoryEnum; // CategoryEnum[message.category] // TS: 5.1.3
    this.targets = message.targets;
    this.status = (<StatusEnum>(<unknown>StatusEnum))[message.status as unknown as number] as StatusEnum; // StatusEnum[message.status] // TS: 5.1.3
    this.number_of_openings = message.number_of_openings;
    this.formatData();
  }

  public formatData(): void {
    this.send_date = DateUtils.toLocalString(new Date(this.send_date));
    this.created_at = DateUtils.toLocalString(new Date(this.created_at));
    this.message_open_object = this.targets !== null ? this.targets + "人" : "- 人";
    this.amount_opened_messages = this.number_of_openings !== null ? this.number_of_openings.toString() : "-";
  }
}

export default MessageItem;
