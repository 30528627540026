import { MaybeRefOrGetter, get, useNow } from "@vueuse/core";
import type { InternalRuleItem, Rule, Rules } from "async-validator";
import {
  REQUIRED_MESSAGE,
  KATAKANA_MESSAGE,
  CUSTOMER_ID_MESSAGE,
  EMAIL_MESSAGE,
  BIRTH_DAY_MESSAGE,
} from "@/common/constants/validate-message.constants";

// refer: https://symbl.cc/en/unicode/table/#halfwidth-and-fullwidth-forms
const katakanaRegex = /^[\u30A0-\u30FF\uFF65-\uFF9Fー]+$/;

const codeRuleFn = (hasEdit: boolean): Record<string, Rule | Rules> =>
  hasEdit
    ? {
        code: [
          {
            type: "string",
            required: true,
            message: REQUIRED_MESSAGE,
          },
          {
            max: 10,
            message: CUSTOMER_ID_MESSAGE,
          },
        ],
      }
    : {};

const rulesFn = (hasEdit: boolean): MaybeRefOrGetter<Record<string, Rule | Rules>> => {
  return {
    sureName: {
      type: "string",
      required: true,
      message: REQUIRED_MESSAGE,
    },
    firstName: {
      type: "string",
      required: true,
      message: REQUIRED_MESSAGE,
    },
    sureNameKana: {
      validator: (_rule: InternalRuleItem, name: string): boolean => (name ? katakanaRegex.test(name) : true),
      message: KATAKANA_MESSAGE,
    },
    firstNameKana: {
      validator: (_rule: InternalRuleItem, name: string): boolean => (name ? katakanaRegex.test(name) : true),
      message: KATAKANA_MESSAGE,
    },
    email: {
      type: "email",
      message: EMAIL_MESSAGE,
      validator: (_rule: InternalRuleItem, email: string): boolean => (email ? true : true),
    },
    birthday: {
      validator: (_rule: InternalRuleItem, day: string): boolean => (day ? new Date(day) < get(useNow()) : true),
      message: BIRTH_DAY_MESSAGE,
    },
    ...codeRuleFn(hasEdit),
  };
};
export default rulesFn;
