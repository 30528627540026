import { MaybeRefOrGetter } from "@vueuse/core";
import type { Rule, Rules } from "async-validator";

const requiredMsg = "*このフィールドは必須です。";

const rules: MaybeRefOrGetter<Record<string, Rule | Rules>> = {
  name: [
    {
      type: "string",
      required: true,
      message: requiredMsg,
    },
    {
      max: 50,
      message: "メニュー名を入れてください (50字)",
    },
  ],
  description: [
    {
      type: "string",
      required: true,
      message: requiredMsg,
    },
    {
      max: 150,
      message: "説明が入ります (150字)",
    },
  ],
  unitPrice: [
    {
      required: true,
      message: requiredMsg,
    },
    {
      validator: (_, val): boolean => !Number.isNaN(parseInt(val)),
      message: "*should be a number",
    },
  ],
  positionNumber: [
    {
      required: true,
      message: requiredMsg,
    },
  ],
};
export default rules;
