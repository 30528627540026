import { MaybeRefOrGetter } from "@vueuse/core";
import type { Rule, Rules } from "async-validator";
type IFormRuleValidation = {
  startTime: string;
  endTime: string;
};
const rules = (fieldTime: IFormRuleValidation): MaybeRefOrGetter<Record<string, Rule | Rules>> => {
  const { startTime: start, endTime: end } = fieldTime;
  const hasValidateHour = () => {
    if (end && start) {
      return end > start;
    }
    return true;
  };
  return {
    startTime: [
      {
        validator: () => hasValidateHour(),
        message: "終了日より大きくすることはできません。",
      },
    ],
    endTime: [
      {
        validator: () => hasValidateHour(),
        message: "開始日より過去にすることはできません。",
      },
    ],
  };
};

export default rules;
