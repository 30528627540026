import http from "../../common/utils/http-common-utils";
import ApiRouterConstants from "../constants/api.router.constants";
import {
  ApiSignInRequest,
  ApiSignInResponse,
  ITag,
  IClient,
  ApiForgotPasswordRequest,
  ApiResetPasswordRequest,
  ApiTagIndexReq,
} from "../typings";
import { AxiosResponse } from "axios";
import { useAxios, EasyUseAxiosReturn, StrictUseAxiosReturn } from "@vueuse/integrations/useAxios";

const ClinicService = {
  listTags: async (
    payload: ApiTagIndexReq
  ): Promise<StrictUseAxiosReturn<AxiosResponse<ITag[], ApiTagIndexReq>, AxiosResponse, any>> => {
    const { execute } = useAxios<AxiosResponse<ITag[], ApiTagIndexReq>>(
      ApiRouterConstants.API_TAGS,
      { method: "GET", params: payload },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
  signIn: async (
    payload: ApiSignInRequest,
    onSuccess: () => void,
    onError: (error: any) => void
  ): Promise<EasyUseAxiosReturn<AxiosResponse<ApiSignInResponse>, AxiosResponse, ApiSignInResponse>> => {
    const { execute } = useAxios<AxiosResponse<ApiSignInResponse>>(
      ApiRouterConstants.API_SIGN_IN,
      {
        method: "POST",
      },
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: (error: any) => {
          onError(error.response?.data?.errors);
        },
      }
    );
    return await execute({ data: payload });
  },
  forgotPassword: async (
    payload: ApiForgotPasswordRequest,
    onSuccess: () => void,
    onError: (error: any) => void
  ): Promise<void> => {
    const { execute } = useAxios<void>(
      ApiRouterConstants.API_FORGOT_PASSWORD,
      {
        method: "POST",
      },
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: error => {
          onError(error);
        },
      }
    );
    await execute({ data: payload });
  },
  resetPassword: async (
    payload: ApiResetPasswordRequest,
    onSuccess: () => void,
    onError: (error: any) => void
  ): Promise<void> => {
    const { execute } = useAxios<void>(
      ApiRouterConstants.API_FORGOT_PASSWORD,
      {
        method: "PUT",
      },
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: error => {
          onError(error);
        },
      }
    );
    await execute({ data: payload });
  },
  client: async (): Promise<EasyUseAxiosReturn<AxiosResponse<IClient>, AxiosResponse, IClient>> => {
    const { execute } = useAxios<AxiosResponse<IClient>>(
      ApiRouterConstants.API_CLIENT,
      {
        method: "GET",
      },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },
};

export default ClinicService;
