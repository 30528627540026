import { useAxios } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";
import http from "@/common/utils/http-common-utils";
import type { ApiRoomIndexReq, ApiRoomReq } from "@/clinic/typings";

const RoomServices = {
  fetchRooms: async (params: ApiRoomIndexReq, onSuccess: () => void, onError: () => void, onFinish: () => void) => {
    const { execute } = useAxios(ApiRouterConstants.API_ROOMS, { method: "GET", params: params }, http, {
      immediate: false,
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        onError();
      },
      onFinish: () => {
        onFinish();
      },
    });
    return await execute();
  },
  deleteRoom: async (params: ApiRoomReq, onSuccess: () => void, onError: () => void) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_ROOMS}/${params.id}`,
      { method: "DELETE", params: { clinic_id: params.clinic_id } },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          onError();
        },
      }
    );
    return await execute();
  },
  saveRoom: async (formObject: any, onSuccess: () => void, onError: (errors: any) => void) => {
    const method = formObject.id ? "PATCH" : "POST";
    const url = formObject.id ? `${ApiRouterConstants.API_ROOMS}/${formObject.id}` : ApiRouterConstants.API_ROOMS;
    const { execute } = useAxios(url, { method: method }, http, {
      immediate: false,
      onSuccess: () => {
        onSuccess();
      },
      onError: (errors: any) => {
        onError(errors.response?.data);
      },
    });
    return await execute({ params: formObject });
  },
};

export default RoomServices;
