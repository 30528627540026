import { MaybeRefOrGetter } from "@vueuse/core";
import type { Rule, Rules } from "async-validator";

const rules: MaybeRefOrGetter<Record<string, Rule | Rules>> = {
  newPassword: {
    type: "string",
    required: true,
    message: "入力内容に誤りがあります",
  },
  passwordConfirmation: {
    type: "string",
    required: true,
    message: "入力内容に誤りがあります",
  },
  resetPasswordToken: {
    type: "string",
    required: true,
    message: "入力内容に誤りがあります",
  },
};
export default rules;
