import NumberUtils from "@/clinic/utils/number-utils";
import { get } from "@vueuse/core";
import { useJwt } from "@vueuse/integrations/useJwt";

export default class {
  public static checkAccessToken(token: string): boolean {
    const { payload } = useJwt(token);
    const { exp } = get(payload) as { data: { id: number; username: string; name: string }; exp: number };
    return exp ? new Date() < NumberUtils.toDate(exp) : false;
  }
}
