import { AxiosResponse } from "axios";
import { AnyFn } from "@vueuse/shared";
import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "../../constants/api.router.constants";
import { useAxios, EasyUseAxiosReturn } from "@vueuse/integrations/useAxios";
import { ApiClinicSettingStaffShiftParams, ISpecialStaffShift } from "../../typings";
import { ApiStaffSpecialShiftCreateReq } from "@/clinic/typings/apis/clinic-settings";

const StaffSpecialShiftServices = {
  get: async (
    params: ApiClinicSettingStaffShiftParams
  ): Promise<EasyUseAxiosReturn<AxiosResponse<ISpecialStaffShift[]>, AxiosResponse, ISpecialStaffShift[]>> => {
    const { execute } = useAxios<AxiosResponse<ISpecialStaffShift[]>, AxiosResponse>(
      ApiRouterConstants.API_STAFF_SPECIAL_SHIFTS,
      { method: "GET", params },
      http,
      { immediate: false }
    );
    return await execute();
  },

  create: async (
    data: ApiStaffSpecialShiftCreateReq,
    onSuccess: AnyFn,
    onError: AnyFn
  ): Promise<EasyUseAxiosReturn<AxiosResponse<any>, AxiosResponse, any>> => {
    const { execute } = useAxios<AxiosResponse<any>, AxiosResponse>(
      ApiRouterConstants.API_STAFF_SPECIAL_SHIFTS,
      { method: "POST", data },
      http,
      { immediate: false, onSuccess, onError }
    );
    return await execute();
  },
};

export default StaffSpecialShiftServices;
