import { AnyFn } from "@vueuse/core";
import { AxiosResponse } from "axios";
import http from "@/common/utils/http-common-utils";
import { useAxios, EasyUseAxiosReturn } from "@vueuse/integrations/useAxios";
import {
  ApiHotpepperCustomerListPayload,
  ApiHotpepperCustomerRes,
  ApiMergedHotpepperCustomerPayload,
} from "@/clinic/typings";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";

const CustomerHotpepperService = {
  getList: async (
    params: ApiHotpepperCustomerListPayload
  ): Promise<
    EasyUseAxiosReturn<AxiosResponse<ApiHotpepperCustomerRes[]>, AxiosResponse, ApiHotpepperCustomerRes[]>
  > => {
    const { execute } = useAxios<AxiosResponse<ApiHotpepperCustomerRes[]>, AxiosResponse, ApiHotpepperCustomerRes[]>(
      `${ApiRouterConstants.API_HOTPEPPER_CUSTOMERS}`,
      {
        method: "GET",
        params,
      },
      http,
      {
        immediate: false,
      }
    );
    return await execute();
  },

  merge: async (payload: ApiMergedHotpepperCustomerPayload, onSuccess: AnyFn, onError: AnyFn): Promise<void> => {
    const { execute } = useAxios<void>(
      `${ApiRouterConstants.API_HOTPEPPER_CUSTOMERS}/merge`,
      {
        method: "POST",
        data: payload,
      },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    await execute();
  },
};

export default CustomerHotpepperService;
