enum ClientLogOutOptionsEnum {
  account_settings = "アカウント設定",
  log_out = "ログアウト",
}
type IClientLogOutOptions = {
  name: string;
  value: string;
};

const ClientLogOutOptions: IClientLogOutOptions[] = Object.entries(ClientLogOutOptionsEnum).map(([name, value]) => ({
  name,
  value,
}));
export { ClientLogOutOptionsEnum, ClientLogOutOptions };
