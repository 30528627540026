import { createApp } from "vue";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@/common/assets/styles/app.scss";
import "@/clinic/assets/styles/clinic.scss";
import "floating-vue/dist/style.css";
import App from "./App.vue";
import router from "./router";
import store from "./common/store";
// user component in primevue
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Avatar from "primevue/avatar";
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Row from "primevue/row";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Textarea from "primevue/textarea";
import TieredMenu from "primevue/tieredmenu";
import VirtualScroller from "primevue/virtualscroller";
import Card from "primevue/card";
import Timeline from "primevue/timeline";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import { LOCALE_CONFIG } from "@/config/primevue-config";
import Carousel from "primevue/carousel";
import Tooltip from "primevue/tooltip";

// Customer imports
import "@/customer/assets/styles/customer.scss";

// common libraries
import VChart from "vue-echarts";
import FloatingVue from "floating-vue";

const app = createApp(App);
app.use(PrimeVue, {
  locale: LOCALE_CONFIG,
});
app.use(FloatingVue);
app.use(store);
app.use(router);
app.use(ToastService);
app.use(ConfirmationService);

// user component in primevue
app.component("InputText", InputText);
app.component("Password", Password);
app.component("Accordion", Accordion);
app.component("AccordionTab", AccordionTab);
app.component("Checkbox", Checkbox);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Row", Row);
app.component("Avatar", Avatar);
app.component("Dropdown", Dropdown);
app.component("Dialog", Dialog);
app.component("Button", Button);
app.component("Textarea", Textarea);
app.component("TieredMenu", TieredMenu);
app.component("VirtualScroller", VirtualScroller);
app.component("Card", Card);
app.component("Timeline", Timeline);
app.component("Carousel", Carousel);

// user component in echart
app.component("v-chart", VChart);

app.directive("tooltip", Tooltip);
app.directive("text-format", {
  mounted(el, binding) {
    el._listner = el.addEventListener("input", (e: any) => {
      if (!binding.value.test(e.data)) {
        el.value = el.value.replace(e.data, "");
      }
    });
  },
  unmounted(el) {
    el.removeEventListener("input", el._listner);
  },
});

app.mount("#app");
