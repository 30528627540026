import http from "../../common/utils/http-common-utils";
import ApiRouterConstants from "../../clinic/constants/api.router.constants";
import { IMessageItem, ApiMessageResponse } from "../typings";
import { AxiosResponse } from "axios";
import { useAxios } from "@vueuse/integrations/useAxios";
import RouterUtils from "@/common/utils/router.util";
import RouterConstants from "@/clinic/constants/router.constants";

const MessageService = {
  list: async (channel_id: number): Promise<AxiosResponse<IMessageItem[]>> => {
    return await http.get<IMessageItem[]>(`${ApiRouterConstants.API_SCHEDULED_MESSAGES}/${channel_id}`, {
      headers: { Accept: "application/json" },
    });
  },
  detail: async (id: number, channel_id: number): Promise<AxiosResponse<ApiMessageResponse>> => {
    return await http.get<ApiMessageResponse>(
      `${ApiRouterConstants.API_SCHEDULED_MESSAGES}?channel_id=${channel_id}&id=${id}`,
      {
        headers: { Accept: "application/json" },
      }
    );
  },
  trashMessage: async (
    id: number,
    channel_id: number,
    onSuccess: () => void,
    onError: (error: any) => void
  ): Promise<void> => {
    const url = `${ApiRouterConstants.API_SCHEDULED_MESSAGES}/${id}?channel_id=${channel_id}`;
    const { execute } = useAxios(url, { method: "DELETE" }, http, {
      immediate: false,
      onSuccess: async () => {
        if (onSuccess) onSuccess();
        await new Promise(resolve => setTimeout(resolve, 1000));
        RouterUtils.goTo(RouterConstants.MESSAGE_ROUTER_NAME);
      },
      onError: error => {
        if (onError) onError(error);
      },
    });
    await execute();
  },
};

export default MessageService;
